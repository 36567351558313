import { useNavigate } from "react-router-dom"
import { useContext, useEffect, useState, useMemo } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth, db, functions } from "../firebase";
import { httpsCallable } from "firebase/functions";
import { getDoc, doc, collection, getDocs, query, where } from "firebase/firestore";
import { Context } from '../Context';

// HOC
import withAuthentication from "../withAuthentication";

// components
import useScreenSize from '../components/useScreenSize';
import Loading from "../components/Loading";
import ProgressCard from "../components/ProgressCard";
import InfoCard from "../components/InfoCard";
import Leaderboard from "../components/Leaderboard";
import OverallProgressCard from "../components/OverallProgressCard";

// UI + images
import CheckIcon from '@mui/icons-material/Check';
// import redCK from '../images/red-ck.png';
// import yellowCK from '../images/yellow-ck.png';
// import greenCK from '../images/green-ck.png';
import ckBGDesktop from '../images/ckBGDesktop.png';
import ckBGTablet from '../images/ckBGTablet.png';
import ckBGMobile from '../images/ckBGMobile.png';
import { styled } from '@mui/material/styles';
import {
    Box, Select, MenuItem, Typography, Grid, List, ListItem, Alert, Snackbar, Button, Paper, Chip, ListItemIcon, ListItemText, Link, Accordion, AccordionSummary, AccordionDetails, useMediaQuery, useTheme
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Hash, Video, Smile, FileQuestion, CheckSquare, Calendar, User, CheckCircle, FileText, UsersRound, Award, Star, Info, CalendarCheck, BookOpen,
} from 'lucide-react';

// new homepage layout + styling
const primaryColor = '#5ABAA2';
const secondaryColor = '#FF6B6B';
const accentColor = '#4ECDC4';
const yellowColor = '#FFA500';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    borderRadius: '10px !important',
    '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
    },
    '&:before': {
        display: 'none',
        borderRadius: '10px !important',
    },
    '&.Mui-expanded': {
        margin: 0,
    },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    minHeight: 56,
    '&.Mui-expanded': {
        minHeight: 56,
    },
    '& .MuiAccordionSummary-content': {
        margin: '12px 0',
        '&.Mui-expanded': {
            margin: '12px 0',
        },
    },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function Homepage() {
    const { userState, loading } = useContext(Context);
    const { role, firstName, program, group, hasChangedPassword, tenantId, uid, tenantName, questionnaires, completedQuizzes, completedVideos } = userState;

    const isTeacher = role === 'teacher' || role === 'admin';
    const isCK = tenantName === "CULTUUR & KUNST" || (program && program[0] === 'MDT Cultuur & Kunst')

    // ---  cloud functions https calls--- //
    const getLeaderboardStats = httpsCallable(functions, "getLeaderboardStats");

    // onboarding state:
    const [userName, setUserName] = useState('');
    const [isOnboardingProcessFinished, setIsOnboardingProcessFinished] = useState(false);
    const [step, setStep] = useState(3);
    const [unFinishedStep, setUnFinishedStep] = useState(0);
    const [showSnackbar, setShowSnackbar] = useState(false);

    // console.log(isOnboardingProcessFinished, 'isOnboardingProcessFinished')
    // console.log(step, 'step')
    // console.log(unFinishedStep, 'unFinishedStep')
    // console.log(group, 'group')

    // UI state:
    const navigate = useNavigate();
    const screenSize = useScreenSize(); // 'mobile', 'tablet', or 'desktop'
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const getBackgroundImage = () => {
        switch (screenSize) {
            case 'mobile':
                return ckBGMobile;
            case 'tablet':
                return ckBGTablet;
            default:
                return ckBGDesktop;
        }
    };
    const ckBackgroundStyle = {
        backgroundImage: `url(${getBackgroundImage()})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    }

    // selected group state:
    const [selectedGroupData, setSelectedGroupData] = useState('');
    const [selectedGroup, setSelectedGroup] = useState('');
    const [groupSessions, setGroupSessions] = useState([]);
    const [totalGroupVideos, setTotalGroupVideos] = useState(0);
    const [totalGroupQuizzes, setTotalGroupQuizzes] = useState(0);
    const [programQuizzes, setProgramQuizzes] = useState([]);
    const [totalGroupQuestionaires, setTotalGroupQuestionaires] = useState(0);
    const [totalGroupSessions, setTotalGroupSessions] = useState(0);
    const [currUserSession, setCurrUserSession] = useState(0);

    // group stats for teachers (how well students are doing in the group):
    const [groupPerformance, setGroupPerformance] = useState({
        averageProgress: 0,
        averageCorrectAnswers: 0,
        averageTotalQuestions: 0,
        totalStudents: 0,
        videosWatchedByStudents: 0,
        quizzesDoneByStudents: 0,
        questionnairesDoneByStudents: 0,
        totalCorrectAnswers: 0,
        totalQuestions: 0,
        averageAttendancePerSession: 0,
        studentsWithoutActivity: [],
    });
    const [leaderboardData, setLeaderboardData] = useState([]);

    // personal stats of user (for students only):
    const [attendanceStudentRecord, setAttendanceStudentRecord] = useState({ attended: 0, total: 0 }); // personal attendance record
    const [onboardingRecord, setOnboardingRecord] = useState({ finished: 0, total: 0 }); // personal onboarding record
    const [overallProgress, setOverallProgress] = useState(0);
    const [hasActivity, setHasActivity] = useState(false);
    const [correctAnswers, setCorrectAnswers] = useState(0);
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [upcomingSessions, setUpcomingSessions] = useState([]);
    const [recentActivities, setRecentActivities] = useState([]);
    const [achievements, setAchivements] = useState([]);

    // console.log(selectedGroupData, 'selectedGroupData')
    // console.log(userState, 'userState')

    // set user's name:
    useEffect(() => {
        if (loading) return
        if (firstName && firstName.length > 0) {
            const userName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
            setUserName(userName)
        }
    }, [firstName, loading]);

    // onabording state of user:
    useEffect(() => {
        if (loading || !uid || !tenantId) return

        if (role === 'admin' || role === 'superAdmin') {
            setIsOnboardingProcessFinished(true)
            setOnboardingRecord({ finished: 3, total: 3 });
            return
        }
        const onboardingData = async () => {
            const userRef = doc(db, 'tenants', tenantId, 'users', uid);
            try {
                const userSnap = await getDoc(userRef);
                const userData = userSnap.data();

                const onboardingData = userData.onboardingData;
                const onboardingProcess = userData.onboardingProcess;
                // teachers have 3 steps, users have 2 steps:
                if (!onboardingData && !onboardingProcess) {
                    if (role === 'teacher') {
                        setStep(3)
                        setUnFinishedStep(1)
                        setOnboardingRecord({ finished: 0, total: 3 });
                        return
                    } else {
                        setStep(2)
                        setUnFinishedStep(1)
                        setOnboardingRecord({ finished: 0, total: 2 });
                        return
                    }
                }

                // check if user finished onboarding process:
                const didUserFinishOboarding = Object.values(onboardingProcess).every((step) => step === true)
                    && Object.values(onboardingProcess).length === 2;
                const didTeacherFinishOboarding = Object.values(onboardingProcess).every((step) => step === true)
                    && Object.values(onboardingProcess).length === 3;

                if (didUserFinishOboarding) {
                    setIsOnboardingProcessFinished(true)
                    setOnboardingRecord({ finished: 2, total: 2 });
                    return
                }
                if (didTeacherFinishOboarding) {
                    setIsOnboardingProcessFinished(true)
                    setOnboardingRecord({ finished: 3, total: 3 });
                    return

                } else {
                    const isfinishedStep1 = onboardingProcess.step1
                    const isfinishedStep2 = onboardingProcess.step2
                    const isfinishedStep3 = onboardingProcess.step3

                    // if user is role === user there's no step 3:
                    if (role && role === 'user') {

                        if (!isfinishedStep1 && !isfinishedStep2) {
                            setUnFinishedStep(1)
                            setStep(2)
                            setOnboardingRecord({ finished: 0, total: 2 });
                            return
                        }
                        if (isfinishedStep1 && !isfinishedStep2) {
                            setUnFinishedStep(2)
                            setStep(1)
                            setOnboardingRecord({ finished: 1, total: 2 });
                            return
                        }
                        if (!isfinishedStep1 && isfinishedStep2) {
                            setUnFinishedStep(1)
                            setStep(1)
                            setOnboardingRecord({ finished: 1, total: 2 });
                            return
                        }
                    }

                    if (!isfinishedStep1 && !isfinishedStep2 && !isfinishedStep3) {
                        setUnFinishedStep(1)
                        setStep(3)
                        setOnboardingRecord({ finished: 0, total: 3 });
                        return
                    }

                    if (isfinishedStep1 && !isfinishedStep2) {
                        setUnFinishedStep(2)
                        setStep(2)
                        setOnboardingRecord({ finished: 1, total: 3 });
                        return
                    }
                    if (isfinishedStep2 && isfinishedStep2 && !isfinishedStep3) {
                        setUnFinishedStep(3)
                        setStep(1)
                        setOnboardingRecord({ finished: 2, total: 3 });
                        return
                    }
                    if (!isfinishedStep1 && isfinishedStep2 && isfinishedStep3) {
                        setUnFinishedStep(1)
                        setStep(1)
                        setOnboardingRecord({ finished: 2, total: 3 });
                        return
                    }

                }
            } catch (error) {
                console.log('cant get data', error)
            }
        }
        onboardingData()
    }, [tenantId, uid, role, loading, program]);

    // send password reset email:
    const handlePasswordReset = async () => {
        try {
            await sendPasswordResetEmail(auth, auth.currentUser.email);
            setShowSnackbar(true);
            console.log('Password reset email sent successfully!');
        } catch (error) {
            console.error('Error sending password reset email:', error);
            alert('Er is iets misgegaan, probeer het later opnieuw:', error)
        }
    };

    const openOnboarding = () => {
        navigate(`/onboarding/step${unFinishedStep}`)
    }

    // for first render setgroup 
    useEffect(() => {
        if (loading || !tenantId || !group) return
        setSelectedGroup(group[0])
    }, [loading, tenantId, group]);

    // useEffect for fetching program data to have the  quizzes data:
    useEffect(() => {
        if (loading || !program || !tenantId) return;

        const fetchProgramQuizzes = async () => {
            try {
                // Reference to the programs collection
                const programsRef = collection(db, 'tenants', tenantId, 'programs');
                const programSnapshot = await getDocs(programsRef);

                if (programSnapshot.empty) {
                    console.log('No programs found.');
                    return;
                }

                // Map through the programs to find the selected one
                const programsData = programSnapshot.docs.map(doc => ({
                    uid: doc.id,
                    ...doc.data()
                }));

                // Assume 'program' is an array and the first element is the selected program ID/name
                const selectedProgramIdentifier = program[0];

                // Find program by UID
                let selectedProgram = programsData.find(p => p.uid === selectedProgramIdentifier);

                // If not found by UID, search by name
                if (!selectedProgram) {
                    selectedProgram = programsData.find(p => p.name === selectedProgramIdentifier);
                }

                if (selectedProgram && selectedProgram.quizzes) {
                    setProgramQuizzes(selectedProgram.quizzes);
                } else {
                    console.warn(`No quizzes found for program: ${selectedProgramIdentifier}`);
                }

            } catch (error) {
                console.log("Error fetching program quizzes: ", error);
            }
        };

        fetchProgramQuizzes();
    }, [loading, program, tenantId]);

    // fetch group info and set its data (videos, quizzes, questionnaires, sessions):
    useEffect(() => {
        if (loading || !selectedGroup || !tenantId) return;

        const fetchGroupInfo = async () => {
            try {
                const groupsRef = collection(db, 'tenants', tenantId, 'groups');
                const groupsSnapshot = await getDocs(groupsRef);

                const groupsData = groupsSnapshot.docs.map(doc => {
                    const data = {
                        id: doc.id,
                        ...doc.data()
                    }
                    return data
                }
                );


                const userGroupData = groupsData.find(group => group.name === selectedGroup)

                if (userGroupData) {
                    setSelectedGroupData(userGroupData)

                    /// Calculate total group activities 
                    const totalGroupQuestionnaires = userGroupData.schedule.reduce((acc, session) => session.questionnaire ? acc + 1 : acc, 0);

                    const totalGroupVideos = userGroupData.schedule.reduce((acc, session) => {
                        if (Array.isArray(session.videos)) {
                            return acc + session.videos.length;
                        }
                        return acc;
                    }, 0);

                    const totalGroupQuizzes = userGroupData.schedule.reduce((acc, session) => {
                        if (Array.isArray(session.videos) && programQuizzes.length > 0) {
                            session.videos.forEach(videoTitle => {
                                const hasQuiz = programQuizzes.some(
                                    quiz => quiz.title.trim().toLowerCase() === videoTitle.trim().toLowerCase()
                                );
                                if (hasQuiz) {
                                    acc += 1;
                                }
                            });
                        }
                        return acc;
                    }, 0);


                    // Set the total number of videos, quizzes, and questionnaires in the group
                    setTotalGroupQuestionaires(totalGroupQuestionnaires)
                    setTotalGroupVideos(totalGroupVideos)
                    setTotalGroupQuizzes(totalGroupQuizzes);

                    // Set the total number of sessions in the group
                    if (userGroupData.schedule) {
                        setTotalGroupSessions(userGroupData.schedule.length)
                        setGroupSessions(userGroupData.schedule)

                        // Find the current session index
                        const currentDate = new Date(); // Current date
                        const currentSessionIndex = userGroupData.schedule.findIndex(session => {
                            // Parsing date from DD-MM-YYYY format
                            const [day, month, year] = session.date.split('-');
                            const sessionDate = new Date(year, month - 1, day, 12, 0, 0); // 12:00 PM to avoid timezone/midnight issues
                            return sessionDate >= currentDate; // Find the first session in the future or today
                        });
                        const upcomingSessions = userGroupData.schedule.filter(session => {
                            const sessionDate = new Date(session.date);
                            const today = new Date();
                            return sessionDate >= today; // Return only sessions that are scheduled after today
                        });

                        setUpcomingSessions(upcomingSessions);
                        setCurrUserSession(currentSessionIndex !== -1 ? currentSessionIndex : null);  // Set current session index (or -1 if no future sessions exist)



                        // calcualte user's attendance history
                        const attendanceCount = userGroupData.schedule.reduce((count, session) => {
                            if (session.attendance) {
                                // Find the student's attendance entry in the current session
                                const studentAttendance = session.attendance.find(att => att.studentName === `${firstName} ${userState.lastName}`);

                                // Check if the student was present
                                if (studentAttendance && (studentAttendance.attendanceStatus === "aanwezig" || studentAttendance.attendanceStatus === "zelfstandig")) {
                                    return count + 1;
                                }
                            }
                            return count;
                        }, 0);

                        const totalSessions = userGroupData.schedule.length;

                        setAttendanceStudentRecord({ attended: attendanceCount, total: totalSessions });

                        if (role !== 'user') {
                            // fetch all students in the group
                            const usersRef = collection(db, 'tenants', tenantId, 'users');
                            const groupQuery = query(usersRef, where("group", "array-contains", selectedGroup));
                            try {
                                const querySnapshot = await getDocs(groupQuery);
                                const allStudents = querySnapshot.docs.map(doc => doc.data()).filter(user => user.role === 'user');

                                // this function calculates all students performance in the group

                                // calculateAllUsersStats(allStudents);
                                if (!allStudents || role === 'user') return

                                // Calculate aggregated stats
                                let totalCorrectAnswers = 0;
                                let totalQuestions = 0;
                                let videosWatchedByStudents = 0;
                                let quizzesDoneByStudents = 0;
                                let questionnairesDoneByStudents = 0;
                                let studentsWithoutActivity = []

                                allStudents.forEach(student => {
                                    videosWatchedByStudents += student.completedVideos ? student.completedVideos.length : 0;
                                    quizzesDoneByStudents += student.completedQuizzes ? student.completedQuizzes.length : 0;
                                    questionnairesDoneByStudents += student.questionnaires ? student.questionnaires.length : 0;

                                    if (student.completedQuizzes) {
                                        student.completedQuizzes.forEach(quiz => {
                                            totalCorrectAnswers += quiz.correctAnswersCount;
                                            totalQuestions += quiz.totalQuestionCount;
                                        });
                                    }

                                    if (!student.completedQuizzes && !student.completedVideos && !student.questionnaires) {
                                        studentsWithoutActivity.push(student);
                                    }
                                });

                                // Calculate average correct answers and average total questions per student
                                const averageCorrectAnswers = allStudents.length > 0
                                    ? (totalCorrectAnswers / allStudents.length).toFixed(2) // Rounded to 2 decimal places
                                    : 0;

                                const averageTotalQuestions = allStudents.length > 0
                                    ? (totalQuestions / allStudents.length).toFixed(2) // Rounded to 2 decimal places
                                    : 0;

                                // Calculate average progress of the group
                                const totalPossibleVideos = totalGroupVideos;
                                const totalPossibleQuizzes = totalGroupQuizzes;
                                const totalPossibleQuestionnaires = totalGroupQuestionaires;

                                const totalPossibleActivities = totalPossibleVideos + totalPossibleQuizzes + totalPossibleQuestionnaires;

                                const totalCompletedActivities = videosWatchedByStudents + quizzesDoneByStudents + questionnairesDoneByStudents;

                                const averageProgress = totalPossibleActivities > 0 && allStudents.length > 0 // Avoid division by zero
                                    ? Math.round((totalCompletedActivities / (totalPossibleActivities * allStudents.length)) * 100)
                                    : 0;


                                // console.log('Calculated average progress:', averageProgress);

                                // Set the calculated data in state
                                setGroupPerformance(prev => {
                                    const newGroupPerformance = {
                                        ...prev,
                                        averageProgress,
                                        averageCorrectAnswers,
                                        averageTotalQuestions,
                                        totalStudents: allStudents.length,
                                        videosWatchedByStudents,
                                        quizzesDoneByStudents,
                                        questionnairesDoneByStudents,
                                        totalCorrectAnswers,
                                        totalQuestions,
                                        studentsWithoutActivity,
                                    };
                                    return newGroupPerformance;
                                });


                            } catch (error) {
                                console.log('error fetching users in group', error)
                            }
                        } else {
                            // this function calculates the user's personal performance in the group
                            if (!completedQuizzes && !completedVideos && !questionnaires) return

                            // calculate user's answers count
                            const correctAnswers = completedQuizzes?.reduce((acc, quiz) => acc + quiz.correctAnswersCount, 0);
                            const totalQuestions = completedQuizzes?.reduce((acc, quiz) => acc + quiz.totalQuestionCount, 0);
                            if (completedQuizzes) {
                                setCorrectAnswers(correctAnswers);
                                setTotalQuestions(totalQuestions);
                            } else {
                                setCorrectAnswers(0);
                                setTotalQuestions(0);
                            }

                            const upcomingSessions = groupSessions.filter(session => {
                                const sessionDate = new Date(session.date);
                                const today = new Date();
                                // Return only sessions that are scheduled after today
                                return sessionDate >= today;
                            });
                            setUpcomingSessions(upcomingSessions);

                            // calculate user's recent activities
                            const allActivities = [
                                ...completedQuizzes && completedQuizzes.map(quiz => ({ icon: FileQuestion, type: 'quiz', title: `Voltooide quiz: ${quiz.quizTitle}`, })), // Replace with real date
                                ...completedVideos && completedVideos.map(video => ({ icon: Video, type: 'video', title: `Video bekeken: ${video.title}`, })),
                                ...questionnaires && questionnaires.map(questionnaire => ({ icon: FileText, type: 'vragenlijst', title: `Ingezonden vragenlijst: ${questionnaire.title}`, }))
                            ];

                            // Sort activities by the completion date (this assumes you will have dates)
                            const sortedActivities = allActivities.sort((a, b) => new Date(b.date) - new Date(a.date));

                            // Limit to last 5 or desired number of activities
                            const recentActivities = sortedActivities.slice(0, 6);
                            setRecentActivities(recentActivities);

                            // Check if there is any activity
                            const hasActivity = recentActivities.length > 0;
                            setHasActivity(hasActivity);

                            // calculate achievements for the user
                            const calculateBadges = (student) => {
                                const badges = [];

                                // Calculate "Snelstarter" badge
                                const signupDate = new Date(student.createdAt.seconds * 1000); // Assuming Firestore timestamp
                                const firstActivityDate = student.completedVideos[0]?.date || student.completedQuizzes[0]?.date || student.questionnaires[0]?.date;
                                if (firstActivityDate) {
                                    const timeToFirstActivity = new Date(firstActivityDate) - signupDate;
                                    const oneDayInMillis = 24 * 60 * 60 * 1000;
                                    if (timeToFirstActivity <= oneDayInMillis) {
                                        badges.push("Snelstarter");
                                    }
                                }

                                // Calculate "Quizmeester" badge
                                const totalQuizzes = student.completedQuizzes.length;
                                const correctAnswers = student.completedQuizzes.reduce((acc, quiz) => acc + quiz.correctAnswersCount, 0);
                                const totalQuestions = student.completedQuizzes.reduce((acc, quiz) => acc + quiz.totalQuestionCount, 0);
                                const quizAccuracy = totalQuestions > 0 ? (correctAnswers / totalQuestions) * 100 : 0;
                                if (totalQuizzes >= 10 || quizAccuracy >= 80) {
                                    badges.push("Quizmeester");
                                }

                                // Calculate "Consistente leerling" badge
                                const allActivityDates = [
                                    ...student.completedVideos && completedVideos.map(video => new Date(video.date)),
                                    ...student.completedQuizzes && completedQuizzes.map(quiz => new Date(quiz.date)),
                                    ...student.questionnaires && questionnaires.map(questionnaire => new Date(questionnaire.date)),
                                ].sort((a, b) => a - b);

                                const consistentParticipation = allActivityDates.every((date, index, arr) => {
                                    if (index === 0) return true;
                                    const timeBetweenActivities = date - arr[index - 1];
                                    const oneWeekInMillis = 7 * 24 * 60 * 60 * 1000;
                                    return timeBetweenActivities <= oneWeekInMillis; // Participation once a week
                                });

                                if (consistentParticipation) {
                                    badges.push("Consistente leerling");
                                }

                                // Calculate "Took First Quiz!" badge
                                if (totalQuizzes > 0) {
                                    badges.push("Eerste quiz gedaan!");
                                }

                                return badges;
                            };
                            const achievements = calculateBadges(userState);
                            setAchivements(achievements)

                            // Calculate overall progress of the group
                            // Handle cases where some programs might not have quizzes
                            const totalPossibleVideos = totalGroupVideos;
                            const totalPossibleQuizzes = totalGroupQuizzes;
                            const totalPossibleQuestionnaires = totalGroupQuestionaires;

                            const totalPossibleActivities = totalPossibleVideos + totalPossibleQuizzes + totalPossibleQuestionnaires;
                            const totalCompletedActivities = completedVideos.length + completedQuizzes.length + questionnaires.length;

                            const overallProgress = totalPossibleActivities > 0
                                ? Math.round((totalCompletedActivities / totalPossibleActivities) * 100)
                                : 0;

                            setOverallProgress(overallProgress);

                        }

                        // Calculate leaderboard for both teachers
                        try {

                            const leaderboardData = await getLeaderboardStats({ group: selectedGroup });
                            setLeaderboardData(leaderboardData.data);
                        } catch (error) {
                            console.log('error fetching leaderboard data', error)
                        }
                    }

                } else {
                    console.log('No group data found')
                    return
                }

            } catch (error) {
                console.log('error fetching group info', error)
            }
        }

        fetchGroupInfo()

    }, [selectedGroup, loading, tenantId, role, firstName, userState, program, group, completedVideos, completedQuizzes, questionnaires, programQuizzes]);


    const programCardInfo = [
        { icon: BookOpen, label: "Programma naam", value: selectedGroupData.program },
        { icon: UsersRound, label: "Groepsnaam", value: selectedGroupData.name },
        // Begin and End date on the same line
        {
            icon: CalendarCheck, label: "Datum", value: selectedGroupData ? (`${selectedGroupData.startDate} - ${selectedGroupData.endDate}`) : ''
        },
        // Tino Contact Person with name, number, and WhatsApp link
        {
            icon: User, label: "Contactpersoon", value: (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography component="span" variant="body1">{selectedGroupData.contactName}</Typography>
                    <Typography component="span" variant="body1">{selectedGroupData.contactNum}</Typography>
                    {selectedGroupData ?
                        <Link
                            href={`https://wa.me/${selectedGroupData.contactNum}`}
                            target="_blank"
                            rel="noreferrer"
                            style={{ fontSize: '0.95rem', display: 'flex', alignItems: 'center', color: '#075E54' }}
                        >
                            WhatsApp
                        </Link>
                        : null}
                </div>
            )
        },

        // School Contact Person with name, number, and WhatsApp link
        {
            icon: User, label: "Contactpersoon school", value: (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography component="span" variant="body1">{selectedGroupData.schoolContactName}</Typography>
                    <Typography component="span" variant="body1">{selectedGroupData.schoolContactNum}</Typography>
                    {/* if selectedGroupData.schoolContactNum is a number show link: */}
                    {
                        selectedGroupData.schoolContactNum && !isNaN(selectedGroupData.schoolContactNum) ?
                            selectedGroupData && selectedGroupData.schoolContactNum ?
                                <Link
                                    href={`https://wa.me/${selectedGroupData.schoolContactNum}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ fontSize: '0.95rem', display: 'flex', alignItems: 'center', color: '#075E54' }}
                                >
                                    WhatsApp
                                </Link>
                                : null
                            : null}
                </div>
            )
        }
    ];

    if (loading) return <Loading />;

    return (
        <div className={`homepage-container ${isCK ? 'bg-overlay' : ''}`}
            style={isCK ? ckBackgroundStyle : {}}>
            {(!hasChangedPassword || hasChangedPassword === false) &&
                <div className="homepage-alert">
                    <>
                        <Paper
                            elevation={4}
                            sx={{ padding: '20px' }}>
                            <div>Om veiligheidsredenen verzoeken wij je jouw wachtwoord opnieuw in te stellen. Nadat je een nieuw wachtwoord heeft gekozen, kan je de app blijven gebruiken </div>
                            <Button
                                variant="contained"
                                sx={{ marginTop: '10px', textTransform: 'none' }}
                                onClick={handlePasswordReset}>
                                Ontvang wachtwoordreset per e-mail
                            </Button>
                        </Paper>
                        <Snackbar
                            open={showSnackbar}
                            autoHideDuration={6000}
                            size="lg"
                            color="primary"
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            onClose={() => setShowSnackbar(false)}
                        >
                            <Alert
                                icon={<CheckIcon fontSize="inherit" />}
                                onClose={() => setShowSnackbar(false)}
                                severity="success"
                                variant="filled"
                                sx={{ width: '100%' }}>
                                E-mail voor het opnieuw instellen van het wachtwoord is succesvol verzonden! Controleer je inbox.
                            </Alert>
                        </Snackbar>
                    </>
                </div>
            }
            {!isOnboardingProcessFinished &&
                <div className="homepage-onboarding-text">
                    <h6 >{role === 'teacher' ? `Laten we jouw klas registreren in 3 gemakkelijke stappen` :
                        'Voltooi het korte registratieproces om aan de slag te gaan en volledige toegang tot jouw portal te krijgen. Het duurt minder dan 5 minuten.'}</h6>
                    <button
                        style={{ margin: '15px 0px 0px' }}
                        className="yellow-btn"
                        onClick={openOnboarding}>
                        {role && role === 'teacher' ? 'Registreer jouw klas' : 'Voltooi de registratie'}
                    </button>
                </div>
            }

            {isTeacher && (
                <div className="homepage-group-select-container">
                    <div className="homepage-group-select">
                        <Select
                            value={selectedGroup || ''}
                            onChange={(e) => setSelectedGroup(e.target.value)}
                            variant="outlined"
                            sx={{ minWidth: '240px', marginBottom: '15px', backgroundColor: isCK ? 'white' : 'transparent' }}
                        >
                            {group && group.map((group, index) => (
                                <MenuItem key={index} value={group}>{group}</MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
            )}

            <Box sx={{ display: 'flex' }}>
                <Box sx={{ flexGrow: 1, }}>
                    {/* progress bar */}
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            {!loading && selectedGroup && (
                                <OverallProgressCard
                                    primaryColor={primaryColor}
                                    accentColor={accentColor}
                                    overallProgress={isTeacher ? groupPerformance.averageProgress : overallProgress}
                                    isMobile={isMobile}
                                />
                            )}
                        </Grid>

                        {/* top cards stats */}
                        <Grid item xs={12} md={8}>
                            <Grid container spacing={3}>
                                <Grid item xs={isMobile ? 6 : 12} sm={4}>
                                    <ProgressCard isMobile={isMobile} icon={Video} isOneNumber={isTeacher ? true : false} label={isTeacher ? "Video's" : "Video's bekeken"} value={isTeacher ?
                                        totalGroupVideos : completedVideos ? completedVideos.length : 0} total={totalGroupVideos} color={secondaryColor} />
                                </Grid>
                                <Grid item xs={isMobile ? 6 : 12} sm={4}>
                                    <ProgressCard isMobile={isMobile} icon={FileQuestion} isOneNumber={isTeacher ? true : false} label={isTeacher ? "Quizzen" : "Quizzen voltooid"} value={isTeacher ?
                                        totalGroupQuizzes : completedQuizzes ? completedQuizzes.length : 0} total={totalGroupQuizzes} color={primaryColor} />
                                </Grid>
                                <Grid item xs={isMobile ? 6 : 12} sm={4}>
                                    <ProgressCard
                                        isMobile={isMobile}
                                        icon={CheckSquare}
                                        isPercentage={isTeacher ? true : false}
                                        label={isTeacher ? "Gemiddelde juiste antwoorden" : "Juiste antwoorden"}
                                        value={isTeacher ? groupPerformance.averageCorrectAnswers : correctAnswers ? correctAnswers : 0}
                                        total={isTeacher ? groupPerformance.averageTotalQuestions : totalQuestions}
                                        color={accentColor} />
                                </Grid>
                                <Grid item xs={isMobile ? 6 : 12} sm={4}>
                                    <ProgressCard isMobile={isMobile} icon={FileText} isOneNumber={isTeacher ? true : false} label={isTeacher ? "Vragenlijsten" : "Vragenlijsten gevuld"} value={isTeacher ?
                                        totalGroupQuestionaires : questionnaires ? questionnaires.length : 0} total={totalGroupQuestionaires} color={primaryColor} />
                                </Grid>
                                {!isTeacher ?
                                    <>
                                        <Grid item xs={isMobile ? 6 : 12} sm={4}>
                                            <ProgressCard isMobile={isMobile} icon={Calendar} label="sessies bijgewoond" value={attendanceStudentRecord.attended} total={attendanceStudentRecord.total} color={secondaryColor} />
                                        </Grid>
                                        <Grid item xs={isMobile ? 6 : 12} sm={4}>
                                            <ProgressCard isMobile={isMobile} icon={CheckCircle} label="Registratiestappen" value={onboardingRecord.finished} total={onboardingRecord.total} color={yellowColor} />
                                        </Grid>
                                    </>
                                    :
                                    <>
                                        <Grid item xs={isMobile ? 6 : 12} sm={4}>
                                            <ProgressCard isMobile={isMobile} isOneNumber={true} icon={UsersRound} label="Studenten in de groep" value={groupPerformance.totalStudents} color={accentColor} />
                                        </Grid>
                                        <Grid item xs={isMobile ? 6 : 12} sm={4}>
                                            <ProgressCard isMobile={isMobile} icon={Hash} label="Sessies voltooid" value={currUserSession ? currUserSession : 0} total={
                                                groupSessions.length} color={yellowColor} />

                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid container spacing={3} sx={{ mt: 2, textAlign: 'left' }} >
                        <Grid item xs={12} md={3}>
                            {isMobile ? (
                                <StyledAccordion>
                                    <StyledAccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography sx={{ fontWeight: 600, color: '#555' }}>Programma informatie</Typography>
                                    </StyledAccordionSummary>
                                    <StyledAccordionDetails>
                                        <List disablePadding >
                                            {programCardInfo && programCardInfo.map((item, index) => (
                                                <ListItem key={index} sx={{ padding: 0 }}>
                                                    <ListItemIcon>
                                                        <item.icon size={18} />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={item.label}
                                                        secondary={
                                                            <>
                                                                {item.value}
                                                                {item.subValue && (
                                                                    <Typography variant="body2" color="text.secondary">
                                                                        {item.subValue}
                                                                    </Typography>
                                                                )}
                                                            </>
                                                        }
                                                        primaryTypographyProps={{ variant: 'body2', sx: { fontWeight: 500 } }}
                                                        secondaryTypographyProps={{ variant: 'body2', fontWeight: 400 }}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </StyledAccordionDetails>
                                </StyledAccordion>
                            ) : (
                                <InfoCard icon={Info} title="Programma informatie" color="#5ABAA2">
                                    <List disablePadding >
                                        {programCardInfo && programCardInfo.map((item, index) => (
                                            <ListItem key={index} sx={{ padding: 0 }}>
                                                <ListItemIcon>
                                                    <item.icon size={18} />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={item.label}
                                                    secondary={
                                                        <>
                                                            {item.value}
                                                            {item.subValue && (
                                                                <Typography variant="body2" color="text.secondary">
                                                                    {item.subValue}
                                                                </Typography>
                                                            )}
                                                        </>
                                                    }
                                                    primaryTypographyProps={{ variant: 'body2', sx: { fontWeight: 500 } }}
                                                    secondaryTypographyProps={{ variant: 'body2', fontWeight: 400 }}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </InfoCard>
                            )}
                        </Grid>

                        <Grid item xs={12} md={3}>
                            {isMobile ? (
                                <StyledAccordion>
                                    <StyledAccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography sx={{ fontWeight: 600, color: '#555' }}>Komt als volgende</Typography>
                                    </StyledAccordionSummary>
                                    <StyledAccordionDetails>
                                        <List disablePadding>
                                            {upcomingSessions.length === 0 ? (
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', mt: 5 }}>
                                                    <CheckCircle size={26} color="#555" />
                                                    <Typography variant="body1" sx={{ color: '#555', fontWeight: 500, mt: 1 }}>
                                                        Geen geplande sessies
                                                    </Typography>
                                                </Box>
                                            ) : (
                                                upcomingSessions.map((session) => (
                                                    <ListItem
                                                        key={session.sessionId}
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'flex-start',
                                                            justifyContent: 'space-between',
                                                            marginBottom: '5px'
                                                        }}
                                                    >
                                                        <ListItemText
                                                            primary={session.topic}
                                                            secondary={session.date}
                                                            primaryTypographyProps={{ variant: 'body1', sx: { fontWeight: 500 } }}
                                                        />
                                                        <button sx={{ color: '#555', bgcolor: 'rgba(255,215,0,0.1)' }}>
                                                            sessie-informatie
                                                            <Info size={18} style={{ marginLeft: '5px' }} />
                                                        </button>
                                                    </ListItem>
                                                ))
                                            )}
                                        </List>
                                    </StyledAccordionDetails>
                                </StyledAccordion>
                            ) : (
                                <InfoCard icon={Calendar} title="Komt als volgende" color={secondaryColor}>
                                    <List disablePadding >
                                        {!upcomingSessions || upcomingSessions.length === 0 ? (
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', mt: 5, textAlign: 'center' }}>
                                                <CheckCircle size={26} color="#777" />
                                                <Typography variant="body1" sx={{ mt: 1 }}>
                                                    Geen geplande sessies
                                                </Typography>
                                            </Box>
                                        ) : (
                                            upcomingSessions && upcomingSessions.map((session) => (
                                                <ListItem
                                                    key={session.sessionId}
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'flex-start',
                                                        justifyContent: 'space-between',
                                                        marginBottom: '5px'
                                                    }}
                                                >
                                                    <ListItemText
                                                        primary={session.topic}
                                                        secondary={session.date}
                                                        primaryTypographyProps={{ variant: 'body1', sx: { fontWeight: 500 } }}
                                                    />
                                                    <Button
                                                        variant="contained"
                                                        size="small" sx={{ color: '#555', backgroundColor: 'lightgrey', textTransform: 'capitalize', mt: 0.5 }}
                                                        onClick={() => navigate(`/yourprogram/`)}
                                                    >
                                                        sessie-informatie
                                                        {/* <Info size={16} style={{ marginLeft: '5px' }} /> */}
                                                    </Button>
                                                </ListItem>
                                            ))
                                        )}
                                    </List>
                                </InfoCard>
                            )}
                        </Grid>

                        <Grid item xs={12} md={3} >
                            {isMobile ? (
                                <StyledAccordion>
                                    <StyledAccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography sx={{ fontWeight: 600 }}>{isTeacher ? 'Studenten zonder activiteit' : 'Uw recente overwinningen'}</Typography>
                                    </StyledAccordionSummary>
                                    <StyledAccordionDetails>
                                        <List disablePadding >
                                            {isTeacher ?
                                                (
                                                    groupPerformance.studentsWithoutActivity.map((student, index) => (
                                                        <ListItem key={index}>

                                                            <ListItemText
                                                                primary={student.displayName}
                                                                primaryTypographyProps={{ variant: 'body1', sx: { fontWeight: 500 } }}
                                                            />
                                                        </ListItem>
                                                    ))
                                                ) :
                                                hasActivity ? (
                                                    recentActivities.map((activity, index) => (
                                                        <ListItem key={index}>
                                                            <ListItemIcon>
                                                                <activity.icon size={18} />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={activity.title}
                                                                primaryTypographyProps={{ variant: 'body1', sx: { fontWeight: 500 } }}
                                                            // secondary={activity.date} // Use the real date
                                                            />
                                                        </ListItem>
                                                    ))
                                                ) : (
                                                    <ListItem >
                                                        <Typography variant="body1" sx={{ color: '#555', fontWeight: 400 }}>
                                                            Nog steeds geen activiteit, ga je gang en begin, veel succes!
                                                        </Typography>

                                                    </ListItem>
                                                )
                                            }
                                        </List>
                                    </StyledAccordionDetails>
                                </StyledAccordion>
                            ) : (
                                <InfoCard icon={Star} title={isTeacher ? `Inactieve studenten (${groupPerformance?.studentsWithoutActivity?.length})` : 'Uw recente overwinningen'} color={accentColor}>
                                    <List disablePadding >
                                        {isTeacher ?
                                            (groupPerformance.studentsWithoutActivity && groupPerformance.studentsWithoutActivity.length > 0) ?
                                                groupPerformance.studentsWithoutActivity.map((student, index) => (
                                                    <ListItem key={index} sx={{ paddingTop: 0 }}
                                                    >
                                                        <Box sx={{ textAlign: 'center', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                                            <ListItemText sx={{ marginRight: '20px' }} >
                                                                {index + 1}.
                                                            </ListItemText>
                                                            <ListItemText
                                                                primary={student.displayName}
                                                                primaryTypographyProps={{ variant: 'body1' }}
                                                            />
                                                        </Box>
                                                    </ListItem>
                                                ))
                                                :
                                                <ListItem sx={{ paddingTop: 0 }}>
                                                    <Box sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                                        <Typography variant="body1" sx={{ color: '#555', fontWeight: 400, mt: 4, pt: 0 }}>
                                                            Geen inactieve studenten
                                                        </Typography>
                                                    </Box>
                                                </ListItem>
                                            :
                                            hasActivity ? (
                                                recentActivities && recentActivities.map((activity, index) => (
                                                    <ListItem key={index}>
                                                        <ListItemIcon>
                                                            <activity.icon size={18} />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={activity.title}
                                                            primaryTypographyProps={{ variant: 'body1', sx: { fontWeight: 400 } }}
                                                        // secondary={activity.date} // Use the real date
                                                        />
                                                    </ListItem>
                                                ))
                                            ) : (
                                                <ListItem sx={{ paddingTop: 0 }}>
                                                    <Box sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                                        <Typography variant="body1" sx={{ color: '#555', fontWeight: 400, mt: 4, pt: 0 }}>
                                                            Nog steeds geen activiteit, ga je gang en begin, veel succes!
                                                        </Typography>
                                                    </Box>
                                                </ListItem>
                                            )
                                        }
                                    </List>
                                </InfoCard>
                            )}
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Leaderboard data={leaderboardData} />
                        </Grid>


                    </Grid>
                    {!isTeacher && (
                        <Grid item xs={12} md={8} sx={{
                            mt: 2,
                            '& .MuiBox-root': {
                                justifyContent: 'center',
                                margin: '15px 0',
                            }
                        }} >

                            <InfoCard icon={Award} title="Jouw prestaties" color="#FFA500" >
                                <Box>
                                    {achievements.length === 0 ? (
                                        <Typography variant="body1" sx={{ color: '#555', fontWeight: 400 }}>
                                            Nog geen prestaties. Hoe meer opdrachten je uitvoert, hoe meer prestaties je kunt ontgrendelen!
                                        </Typography>
                                    ) :


                                        achievements.map((achievement, index) => (
                                            <Chip
                                                key={index}
                                                label={achievement}
                                                sx={{ m: 0.5, backgroundColor: '#FFD700', color: '#333', fontWeight: 600 }}
                                            />
                                        ))}
                                </Box>
                            </InfoCard>
                        </Grid>
                    )}

                </Box>
            </Box>

            <div className="onboarding-banner">
                {(!isOnboardingProcessFinished) &&
                    <>
                        <p style={{ fontWeight: '500', color: 'white' }}>
                            {userName}, je hebt <span style={{ color: '#FCDF30' }}>{step} {(step === 1 ? 'stap' : 'staps')} over </span>
                            om het registratieproces te voltooien</p>
                        <button
                            style={{ padding: '7px' }}
                            onClick={openOnboarding}
                            className="yellow-btn" >
                            Voltooi de registratie
                        </button>
                    </>
                }
            </div>
        </div >
    );
}
export default withAuthentication(Homepage);

// fetch students in the group:
// useEffect(() => {
//     if (loading || !group || group.length === 0 || !selectedGroup) return;

//     const fetchStudents = async () => {
//         try {
//             const usersRef = collection(db, 'tenants', tenantId, 'users');
//             const groupQuery = query(usersRef, where("group", "array-contains", selectedGroup));
//             const querySnapshot = await getDocs(groupQuery);

//             // Fetch students and filter
//             const students = querySnapshot.docs.map((doc) => doc.data());

//             // Filter users who are students and part of the selected group
//             const studentsInGroup = students.filter(student => student.group.includes(selectedGroup) && student.role === 'user');

//             // Aggregate performance data
//             const leaderboardDataData = studentsInGroup.map(student => {
//                 const videosWatched = student.completedVideos ? student.completedVideos.length : 0;
//                 const quizzesCompleted = student.completedQuizzes ? student.completedQuizzes.length : 0;
//                 const questionnairesCompleted = student.questionnaires ? student.questionnaires.length : 0;
//                 const totalCorrectAnswers = student.completedQuizzes ? student.completedQuizzes.reduce((acc, quiz) => acc + quiz.correctAnswersCount, 0) : 0;

//                 // Simple total activity count
//                 const performanceScore = videosWatched + quizzesCompleted + questionnairesCompleted + totalCorrectAnswers;

//                 return {
//                     displayName: student.displayName,
//                     performanceScore,
//                     videosWatched,
//                     quizzesCompleted,
//                     questionnairesCompleted,
//                     totalCorrectAnswers,
//                 };
//             });

//             // Sort leaderboardData by total activity
//             const sortedLeaderboard = leaderboardDataData.sort((a, b) => b.performanceScore - a.performanceScore);

//             // Set the sorted leaderboardData data in state
//             setLeaderboardData(sortedLeaderboard);

//         } catch (error) {
//             console.log('error', error);
//         }
//     };

//     fetchStudents();
// }, [group, loading, selectedGroup, tenantId]);