import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { Context } from './Context';

const withAuthentication = (WrappedComponent, adminOnly, teacherOnly, superAdminOnly) => {

    return (props) => {
        const navigate = useNavigate();
        const { userState, loading} = useContext(Context);
        const { isSuperAdmin  } = userState;
        
        useEffect(() => {
            if (loading) return;
            
            if (userState.uid && adminOnly && userState.role !== 'admin') {
                // console.log('user is not admin, redirecting to homepage')
                navigate('/');
            } else if (userState.uid && teacherOnly && userState.role !== 'teacher')  {
                // console.log('user is not teacher, checking if user is admin')
                // check if user is admin:
                if (userState.uid && userState.role === 'admin'){
                    // console.log('teacher page, user is admin')
                }
                if (userState.uid && userState.role !== 'admin') {
                // console.log('teacher page, user also is not admin, redirecting to homepage')
                navigate('/');
                }
            } else if (userState.uid && superAdminOnly && !isSuperAdmin) {
                // console.log(isSuperAdmin, 'user is not superAdmin, redirecting to homepage')
                navigate('/');

            } else if (!userState.uid) {
                // console.log('no user logged in, redirecting to landing page' )
                navigate('/landing');
            }

        }, [navigate, userState, loading, isSuperAdmin]);

        return <WrappedComponent {...props} />;
    };
};

export default withAuthentication;
