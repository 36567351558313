import { useNavigate, useLocation } from "react-router-dom"
import { useContext, useState } from 'react';
import { Context } from '../Context';
import { db } from '../firebase';
import { getDoc, doc, setDoc } from "firebase/firestore";
// import withAuthentication from "../withAuthentication";
import { confirmPasswordReset } from 'firebase/auth';
import { auth } from '../firebase';
import Loading from "../components/Loading";
import { Snackbar, Button } from '@mui/material';

const Profile = () => {
    const { userState, loading } = useContext(Context);
    const { uid, tenantId } = userState;
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    // const { oobCode } = useParams();

    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const mode = searchParams.get('mode');
    const actionCode = searchParams.get('oobCode');
    const [showSnackbar, setShowSnackbar] = useState(false);


    const validatePassword = (password) => {
        if (password.length < 6) {
            alert('Password must be at least 6 characters');
            return false;
        }
        const regex = /^(?=.*[A-Za-z])(?=.*\d).{6,}$/;
        if (!regex.test(password)) {
            alert('Password must contain at least one letter and one number');
            return false;
        }
        return true;
    };

    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        if (!validatePassword(newPassword)) return;

        if (newPassword === confirmPassword) {
            try {
                await confirmPasswordReset(auth, actionCode, newPassword);

                if (uid && tenantId) {
                    // Update Firestore that password has been reset
                    await updateHasChangedPassword();

                }
                // Only if update is successful, show snackbar and proceed with logout
                setShowSnackbar(true);

                // wait 3 seconds
                setTimeout(() => {
                    // logout user
                    auth.signOut();
                    // navigate to login page
                    navigate('/login');
                }, 3000);

            } catch (error) {
                alert('De link voor het opnieuw instellen van het wachtwoord is ongeldig of verlopen. Ga terug naar de startpagina en klik op het opnieuw instellen van het wachtwoord om een ​​nieuwe link te krijgen');
                console.log(error);
            }

        }
        else {
            alert('Wachtwoorden komen niet overeen');
        }
        // Reset form
        setNewPassword('');
        setConfirmPassword('');
    };


    // update firestore that password has been reset with hasChangedPassword = true
    const updateHasChangedPassword = async () => {
        try {
            const userRef = doc(db, 'tenants', tenantId, 'users', uid);
            const userSnap = await getDoc(userRef);
            if (!userSnap.exists()) {
                console.log('user does not exist');
                return
            }
            await setDoc(userRef, {
                hasChangedPassword: true
            }, { merge: true });
            console.log('hasChangedPassword updated');
        } catch (error) {
            console.log(error);
        }
    }
    if (loading) return <Loading />;

    return (
        <div className='page-container '>
            <div className='page-title'>
                {/* <h3>Profile Page</h3> */}
            </div>
            <Snackbar
                open={showSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={7000}
                onClose={() => setShowSnackbar(false)}
                message="Wachtwoord is succesvol gereset! U wordt doorgestuurd naar de inlogpagina"
                action={
                    <Button color="secondary" size="small" onClick={() => setShowSnackbar(false)}>
                        Close
                    </Button>
                }
            />
            <div style={{ justifyContent: 'center', alignItems: 'center' }} className='page-content'>
                <div className='password-section'>
                    <div className='password-title'>
                        <h4 style={{ color: 'var(--primary)', marginBottom: '20px' }} >Wachtwoord opnieuw instellen</h4>
                        <p>Het wachtwoord moet minimaal 12 tekens lang zijn en één cijfer en één letter bevatten</p>
                    </div>
                    <form onSubmit={handleResetPassword}>
                        {/* <label htmlFor="newPassword">New Password</label> */}

                        <input
                            type="password"
                            value={newPassword}
                            onChange={handleNewPasswordChange}
                            placeholder="Voer een nieuw wachtwoord in"
                        />
                        <br />
                        {/* <label htmlFor="confirmPassword">Confirm Password</label> */}
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            placeholder="Voer opnieuw een nieuw wachtwoord in" />

                        <br />
                        <button style={{ margin: '20px 0px' }} type="submit">Nieuw wachtwoord opslaan</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Profile
