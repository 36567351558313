import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Home as HomeIcon, SentimentDissatisfied as SadFaceIcon } from '@mui/icons-material';

const NotFound = () => {
    const navigate = useNavigate();
    return (
        <div className="not-found-container">
            <h1 className="not-found-title">404</h1>
            <h4 className="not-found-subtitle">MDT Portaal</h4>
            <p className="not-found-subtitle">Oeps! Pagina niet gevonden</p>
            <div className="not-found-icon">
                <SadFaceIcon style={{ fontSize: 64, color: '#3f51b5' }} />
            </div>
            <p className="not-found-message">De pagina die u zoekt, is mogelijk verwijderd of is tijdelijk niet beschikbaar.</p>
            <button
                onClick={() => navigate('/')} className="not-found-button">
                <HomeIcon style={{ marginRight: '8px' }} />
                Ga naar de startpagina
            </button>
        </div>
    );
};

export default NotFound;