import React from 'react';
import { Box, Card, CardContent, Typography, LinearProgress, Avatar, } from '@mui/material';

const ProgressCard = ({ isOneNumber, icon: Icon, label, value, total, color, isPercentage, isMobile }) => {
    const progress = total > 0 ? ((value / total) * 100).toFixed(2) : 0;

    return (
        <Card sx={{
            height: '100%',
            borderRadius: 4,
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
            '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
            }
        }}>
            <CardContent sx={{ p: 2 }}>
                <Box display="flex" alignItems={isMobile ? "flex-start" : "center"} textAlign="left" mb={1}>
                    <Avatar sx={{ alignItems: isMobile ? "flex-start" : "center", bgcolor: 'transparent', color: color, width: 30, height: 30, mr: 1 }}>
                        <Icon size={18} />
                    </Avatar>
                    <Typography variant="subtitle2" component="div" sx={{ fontWeight: 600, color: '#555' }}>
                        {label}
                    </Typography>
                </Box>
                <Typography variant="h6" component="div" gutterBottom sx={{ fontWeight: 700, color: color }}>
                    {isOneNumber ? value : isPercentage ? `${progress}%` : `${value} / ${total}`}

                </Typography>
                {(!isOneNumber && !isPercentage) && (

                    <LinearProgress
                        variant="determinate"
                        value={(value / total) * 100}
                        sx={{
                            height: 8,
                            borderRadius: 4,
                            backgroundColor: '#e0e0e0',
                            '& .MuiLinearProgress-bar': {
                                backgroundColor: color,
                            }

                        }}
                    />
                )}
            </CardContent>
        </Card>
    );
};
export default ProgressCard;