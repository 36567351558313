
function Loading() {
    return (
        <div className="loading-container">
            <div>
                <h3>Bezig met laden...</h3>
            </div>
            <div style={{ marginTop: '25px' }}>
                <i className="fas fa-spinner fa-spin fa-xl"></i>
            </div>
        </div>
    )
}
export default Loading;
