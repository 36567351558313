import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getAuth, browserLocalPersistence, setPersistence } from "firebase/auth";
import { getStorage } from "firebase/storage";


// Your web app's Firebase configuration

const firebaseConfig = {
  // deepcode ignore HardcodedNonCryptoSecret: open api key
  apiKey: "AIzaSyArvL37kOnjLdPfga7MjjJoDzFQNupKTqs",
  authDomain: "mymdt-ec13d.firebaseapp.com",
  projectId: "mymdt-ec13d",
  storageBucket: "mymdt-ec13d.appspot.com",
  messagingSenderId: "439987882519",
  appId: "1:439987882519:web:2feaa6d43e9660abea2a52",
  measurementId: "G-FT938BRJD2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// const firestore = getFirestore(app);
const functions = getFunctions(app);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

// set auth persistence to local
setPersistence(auth, browserLocalPersistence);

export { db, firebaseConfig, app, auth, functions, storage };