import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../Context";
import Loading from "../components/Loading";
import { Select, MenuItem } from '@mui/material';
import useInView from "../hooks/useInView";
import ScrollTriggeredLottie from "../components/ScrollTriggeredLottie";

// images + animations
import arrowAnimation from '../animations/arrowAnimation.json';
import homepageImg2 from '../images/homepageImg1.png';
import dashboard from '../images/dashboard.png';
import programImg from '../images/portal-program.png';
import videoPage from '../images/video-page.png';
import ckpage from '../images/ck-page.png';
import lightbulb from '../images/lightbulb.png';
import star from '../images/star.png';


function LandingPage() {
    const { loading } = useContext(Context);
    const [language, setLanguage] = useState('nl');  // 'en' for English, 'nl' for Dutch
    const navigate = useNavigate();
    const [scroll, setScroll] = useState(0);
    const [isMobile, setIsMobile] = useState(false);    // check if the device is mobile
    const [isTablet, setIsTablet] = useState(false);    // check if the device is tablet

    // page scroll
    useEffect(() => {
        const onScroll = () => {
            setScroll(window.scrollY);
        }
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, []);

    // Scroll animation
    useEffect(() => {
        const elements = document.querySelectorAll('.animated');
        elements.forEach((element) => {
            const rect = element.getBoundingClientRect();
            const isShown = rect.top <= window.innerHeight && rect.bottom >= 0;
            if (isShown) {
                element.classList.remove('hidden');
                element.classList.add('fadeInUp');
            } else {
                element.classList.remove('fadeInUp');
                element.classList.add('hidden');
            }
        });
    }, [scroll]);

    // check mobile
    useEffect(() => {
        const checkMobile = () => {
            if (window.innerWidth <= 768) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
            if (window.innerWidth <= 1024) {
                setIsTablet(true);
            } else {
                setIsTablet(false);
            }
        }
        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => {
            window.removeEventListener('resize', checkMobile);
        }
    }, []);

    // check if the element is in view
    const [animationRef, isAnimationInView] = useInView({
        threshold: 0.5, // Trigger when 50% of the element is visible
    });

    // translate content based on language
    const translations = {
        en: {
            title: "Digitise Your MDT Program",
            subTitle: "Your entry gate to the MDT program",
            description: `Your digital companion throughout your MDT journey.
            Dive deep into a world of resources, activities, and interactive content tailored for participants.
             Whether you are a student, teacher, or program organizer, we've got you covered.`,

            div1: {
                title: "Your One-Stop MDT Platform",
                subTitle: "Manage your MDT program with ease",
                description: "From registration to participation, schedules, workshop materials or real-time updates, find everything you need to streamline your MDT experience.",
            },
            div2: {
                title: "E-learning Platform for your MDT program",
                subTitle: "Videos, quizzes, and interactive content",
                description: "Our e-learning platform is designed to provide your students with the best learning experience. With videos, quizzes, and interactive content, they can learn at your own pace and you and their teachers can track their progress.",
            },
            div3: {
                title: "Interested in MDT Portal?",
                subTitle: "See what MDT Portal can do for you",
                description: "Using MDT Portal for managing your MDT program can help speed up work and save valuable time. Students can register for the program, view your schedule, and access all the resources. Teachers can view their students' progress, and program organizers can manage their groups and participants. Contact us about purchasing the Portal for your MDT.",
            },
            div4: {
                title: "Match your brand colors",
                subTitle: "Customize your MDT Portal",
                description: "Match your brand colors and logo to your MDT Portal. We can customize the portal to match your brand colors and logo. Contact us for more information.",
            },
            footer: {
                company: "Company",
                login: "Login",
                contact: "Contact Us"
            }
        },
        nl: {
            title: "Digitaliseer uw MDT-programma",
            subTitle: 'Jouw toegangspoort tot het MDT-programma',
            description: "Je uitgebreide digitale metgezel gedurende je MDT-reis. Duik diep in een wereld vol bronnen, activiteiten en interactieve content op maat gemaakt voor onze deelnemers. Of je nu een student, leraar of programma-organisator, wij zorgen voor je.",
            div1: {
                title: "Jouw Alles-in-één MDT Platform",
                subTitle: "Beheer uw MDT-programma met gemak",
                description: "Van registratie tot deelname, van schema's, workshopmateriaal tot realtime updates: vind alles wat u nodig heeft om uw MDT-ervaring te stroomlijnen.",
            },
            div2: {
                title: "E-learning Platform voor uw MDT-programma",
                subTitle: "Video's, quizzen en interactieve inhoud",
                description: "Ons e-learningplatform is ontworpen om uw studenten de beste leerervaring te bieden. Met video's, quizzen en interactieve inhoud kunnen ze in uw eigen tempo leren en kunnen u en hun docenten hun voortgang volgen",
            },
            div3: {
                title: "Geïnteresseerd in MDT Portaal?",
                subTitle: "Bekijk wat MDT Portaal voor u kan betekenen",
                description: "Het gebruik van MDT Portaal voor het beheren van uw MDT-programma kan het werk versnellen en kostbare tijd besparen. Studenten kunnen zich inschrijven voor het programma, uw rooster bekijken en toegang krijgen tot alle bronnen. Docenten kunnen de voortgang van hun leerlingen bekijken en programma-organisatoren kunnen hun groepen en deelnemers beheren. Neem contact met ons op over de aanschaf van de portaal voor uw MDT."
            },
            div4: {
                title: "Match uw merkkleuren",
                subTitle: "Pas uw MDT Portaal aan",
                description: "Match uw merkkleuren en logo met uw MDT Portaal. We kunnen het portaal aanpassen aan uw merkkleuren en logo. Neem contact met ons op voor meer informatie."
            },
            footer: {
                company: "Bedrijf",
                login: "Inloggen",
                contact: "Contact"
            }
        },
    };

    if (loading) return <Loading />

    return (
        <div className="landingPage">
            <div className="landing-header" >
                <div className="landing-logo">
                    <a href="/landing">
                        <h1 >MDT {language === 'en' ? 'Portal' : 'Portaal'}</h1>
                    </a>
                </div>
                <div >
                    <Select
                        className=" "
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                        sx={{
                            "&:hover .MuiSvgIcon-root": {
                                color: 'var(--global-black)',
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                border: 'none'
                            }

                        }}
                    >
                        <MenuItem value="en">English</MenuItem>
                        <MenuItem value="nl">Dutch</MenuItem>
                    </Select>
                    <button onClick={(e) => (navigate("/login"))}
                        className="loginLPButton ">{translations[language].footer.login}
                    </button>
                </div>
            </div>
            <div className="landing-main-container">
                <div className="hero-section ">
                    <div className="hero-left-container">
                        <h1 className="animated hidden">{translations[language].title}</h1>
                        <p >{translations[language].description}</p>
                        {isMobile || isTablet ? null :
                            <button className=" yellow-btn"
                            >
                                <a
                                    style={{ color: 'var(--global-black)' }}
                                    href={`mailto:info@tinomdt.nl`}>
                                    {translations[language].footer.contact}
                                </a>
                            </button>
                        }
                    </div>
                    <div className="hero-right-container">
                        <img className="animated hidden" src={dashboard} alt="mdt-homepage" />
                    </div>
                    {isMobile || isTablet ?
                        <button className=" yellow-btn"
                        >
                            <a
                                style={{ color: 'var(--global-black)' }}
                                href={`mailto:info@tinomdt.nl`}>
                                {translations[language].footer.contact}
                            </a>
                        </button>
                        : null
                    }

                </div>
                <ScrollTriggeredLottie
                    animationData={arrowAnimation}
                    style={{
                        width: isMobile ? '250px' : '400px',
                        height: '200px',
                        transform: 'rotate(15deg)',
                        // if on mobile, smaller arrow
                    }}
                />
                <div className="featureSection div1" >

                    <div className="featureSection-left-container">
                        <img className="animated hidden" src={programImg} alt="tino-homepage" />
                    </div>
                    <div className="featureSection-right-container animated hidden">
                        <img
                            src={star ? star : ''}
                            style={{ width: '120px', transform: 'rotate(20deg)' }}
                            alt="star"

                        />
                        <h2 className="animated hidden">{translations[language].div1.title}</h2>
                        <h5>{translations[language].div1.subTitle}</h5>
                        <p>{translations[language].div1.description}</p>
                    </div>
                </div>
                <ScrollTriggeredLottie
                    animationData={arrowAnimation}
                    style={{
                        width: isMobile ? '250px' : '400px',
                        height: '200px',
                        transform: 'rotate(90deg)'
                    }}
                />
                <div className="featureSection div2" >
                    <div className="featureSection-right-container animated hidden">
                        <img
                            src={star ? star : ''}
                            style={{ width: '120px', transform: 'rotate(20deg)' }}
                            alt="star"

                        />
                        <h2 className="animated hidden">{translations[language].div2.title}</h2>
                        <h5>{translations[language].div2.subTitle}</h5>
                        <p>{translations[language].div2.description}</p>
                    </div>
                    <div className="featureSection-left-container">
                        <img className="animated hidden" src={videoPage} alt="tino-homepage" />
                    </div>
                </div>
                <ScrollTriggeredLottie
                    animationData={arrowAnimation}
                    style={{
                        width: isMobile ? '250px' : '400px',
                        height: '200px',
                        transform: 'rotate(15deg)'
                    }}
                />

                <div className="featureSection div4" >

                    <div className="featureSection-left-container">
                        <img className="animated hidden" src={ckpage} alt="tino-homepage" />
                    </div>
                    <div className="featureSection-right-container animated hidden">
                        <img
                            src={star ? star : ''}
                            style={{ width: '120px', transform: 'rotate(20deg)' }}
                            alt="star"

                        />
                        <h2 className="animated hidden">{translations[language].div4.title}</h2>
                        <h5>{translations[language].div4.subTitle}</h5>
                        <p>{translations[language].div4.description}</p>
                    </div>
                </div>
                <div className="featureSection div3">
                    <img
                        src={lightbulb ? lightbulb : ''}
                        style={{ width: '120px', transform: 'rotate(340deg)' }}
                        alt="lightbulb"

                    />
                    <h2 className="animated hidden">{translations[language].div3.title}</h2>
                    <h5 >{translations[language].div3.subTitle}</h5>
                    <p>{translations[language].div3.description}</p>
                    <button className="animated hidden yellow-btn" style={{ marginTop: '30px', backgroundColor: 'var(--global-yellow)' }} >
                        <a style={{ color: 'var(--global-black)' }}
                            href={`mailto:info@tinomdt.nl`}>{translations[language].footer.contact}</a>
                    </button>
                </div>
            </div>

            <div className="landing-footer" style={{ backgroundColor: "var(--global-black)" }}>
                <div>
                    <a href="https://www.tinomdt.nl" target="_blank" rel="noopener noreferrer">{translations[language].footer.company}</a>
                    <a href="/login">{translations[language].footer.login}</a>
                    <a href={`mailto:info@tinomdt.nl`}>{translations[language].footer.contact}</a>
                    <a href="/privacy" style={{ color: 'white' }}>Privacy Policy</a>
                    <a href="/terms" style={{ color: 'white' }}>Terms and Conditions</a>
                </div>

                <div>
                    <p>MDT Portal &copy; {new Date().getUTCFullYear()}</p>
                </div>
            </div>
        </div>

    );

}

export default LandingPage;