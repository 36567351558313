import * as React from 'react';
import { useState, useMemo, useContext, useEffect } from 'react';
import { Context } from '../Context';

// UI imports:
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';

import { MenuItem, Select, Accordion, AccordionSummary, AccordionDetails, CardContent, Card, FormControl, InputLabel, InputAdornment, TextField, InputLabelProps } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function StudentsTable(studentsData) {
    const { loading } = useContext(Context);
    // const { videos, quizzes, questionnaires } = userState;
    const [searchInput, setSearchInput] = useState(''); // search input for mobile/tablet:
    const [rows, setRows] = useState([]);

    // studentsData is an object with a 'students' key that contains the student list
    const studentList = studentsData.students
    // const programInfo = studentsData.programData
    // *group* data (not the whole program):
    const groupVideos = studentsData.totalVideos
    const groupQuizzes = studentsData.totalQuizzes
    const groupQuestionnaires = studentsData.totalQuestionaires

    // UI
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    // create data for rows:
    function createData(name, videos, quizzes, questionnaires, onboardingProcess) {
        const totalQuizzes = (quizzes && quizzes.length > 0) ? quizzes.length : 0;
        const totalCorrectAnswers = quizzes ? quizzes.reduce((acc, quiz) => acc + quiz.correctAnswersCount, 0) : 0;
        const totalQuestions = quizzes ? quizzes.reduce((acc, quiz) => acc + quiz.totalQuestionCount, 0) : 0;
        const totalVideos = (videos && videos.length > 0) ? videos.length : 0;
        const totalQuestionnaires = (questionnaires && questionnaires.length > 0) ? questionnaires.length : 0;

        // Concatenate quizzes count with correct/total answers
        const quizzesDisplay = totalQuestions > 0
            ? `${totalQuizzes} (${totalCorrectAnswers}/${totalQuestions})`
            : `${totalQuizzes} (0/0)`;

        return {
            name,
            videos: ((videos && videos.length > 0) ? videos.length : 0),
            totalQuizzes,
            quizzes: quizzesDisplay, // Use the concatenated string
            questionnaires: ((questionnaires && questionnaires.length > 0) ? questionnaires.length : 0),
            completedOnboarding: ((onboardingProcess && onboardingProcess.step1 && onboardingProcess.step2) ? 'yes' : 'no')
        };
    }

    // create rows:
    useEffect(() => {
        if (loading || !studentList || studentList.length === 0) return
        const rows = studentList.map(student => createData(
            student.displayName,
            student.completedVideos,
            student.completedQuizzes,
            student.questionnaires,
            student.onboardingProcess))
        setRows(rows)
    }, [loading, studentList]);

    // sorting functions:
    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        if (orderBy === 'registration') {
            return order === 'desc'
                ? (a, b) => (a.registration === 'yes' ? -1 : a.registration === b.registration ? 0 : 1)
                : (a, b) => (a.registration === 'yes' ? 1 : a.registration === b.registration ? 0 : -1);
        }
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }
    // data for table head:
    const headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Naam student',
        },
        {
            id: 'completedOnboarding',
            numeric: false,
            disablePadding: true,
            label: 'Voltooide registratie',

        },
        {
            id: 'videos',
            numeric: true,
            disablePadding: true,
            label: `Video's gezien (van de ${groupVideos})`,
        },
        {
            id: 'quizzes',
            numeric: true,
            disablePadding: true,
            label: `Gemaakte quizzen (van de ${groupQuizzes}) juiste/totaal`
            ,
        },
        {
            id: 'questionnaires',
            numeric: true,
            disablePadding: true,
            label: `Ingevulde vragenlijsten (van de ${groupQuestionnaires})`,
        },
    ];

    function EnhancedTableHead(props) {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
            props;
        const createSortHandler = (property) => (event) => {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        };

        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={'center'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                sx={{ fontWeight: '500', fontSize: '0.90rem', maxWidth: '260px' }}
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={(e) => handleRequestSort(e, headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };

    function EnhancedTableToolbar(props) {
        const { numSelected } = props;

        return (
            <Toolbar
                sx={{
                    display: 'flex', justifyContent: 'space-between', fontSize: '1rem', fontWeight: '500',
                    // pl: { sm: 2 },
                    // pr: { xs: 1, sm: 1 },
                    ...(numSelected > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    }),
                }}
            >
                {numSelected > 0 ? (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} geselecteerd
                    </Typography>
                ) : (
                    <Typography
                        sx={{ fontSize: '1.1rem', fontWeight: '500' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        Resultaten en prestaties van studenten
                    </Typography>
                )}

                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title="Filter list">
                        <IconButton>
                            {/* <FilterListIcon /> */}
                        </IconButton>
                    </Tooltip>
                )}
                <TextField
                    variant="outlined"
                    className='search-student-input'
                    value={searchInput || ''}
                    onChange={handleSearchInputChange}
                    placeholder="Zoek student..."
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        padding: '10px',
                    }}
                    sx={{ width: '220px', padding: '10px 0px', }}

                />
            </Toolbar>
        );
    }

    EnhancedTableToolbar.propTypes = {
        numSelected: PropTypes.number.isRequired,
    };

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('null');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // const handleChangeDense = (event) => {
    //     setDense(event.target.checked);
    // };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = useMemo(
        () =>
            stableSort(rows, (a, b) => {
                // Check if 'orderBy' is a valid column id, otherwise default to 'name'
                const compareBy = headCells.find((cell) => cell.id === orderBy) ? orderBy : 'name';
                if (compareBy === 'registration') {
                    const aValue = a.registration || ''; // Handle missing property
                    const bValue = b.registration || ''; // Handle missing property
                    // Sort based on 'registration' column
                    return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
                } else {
                    // For other columns, use the existing getComparator function
                    return getComparator(order, compareBy)(a, b);
                }
            }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
        [order, orderBy, page, rowsPerPage, rows, headCells]
    );

    // Handler for the search input change
    const handleSearchInputChange = (event) => {
        console.log('searched term', event.target.value)
        setSearchInput(event.target.value);
    };

    // Function to filter rows by search input
    const getFilteredRows = (rows, filter) => {
        return rows.filter((row) => row.name.toLowerCase().includes(filter.toLowerCase()));
    };


    return (
        // mobile card layout if mobile or tablet:
        isMobile || isTablet ? (
            <>
                <Card style={{ borderRadius: '10px' }}>
                    <CardContent>
                        <Typography
                            variant='h6'
                            sx={{ textAlign: 'center', color: 'var(--primary)', fontSize: '1rem' }}>
                            Resultaten en prestaties van studenten
                        </Typography>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: '10px', alignItems: 'center' }}>
                            <FormControl fullWidth margin="normal" style={{ marginBottom: '10px' }}>
                                <InputLabel id="sort-by-label">Sorteer op</InputLabel>
                                <Select
                                    labelId="sort-by-label"
                                    id="sort-by-select"
                                    value={orderBy}
                                    style={{ width: '100%' }}
                                    label="Sorteer op"
                                    onChange={(event) => {
                                        const isAsc = orderBy === event.target.value && order === 'asc';
                                        setOrder(isAsc ? 'desc' : 'asc');
                                        setOrderBy(event.target.value);
                                    }}
                                >
                                    {headCells.map((headCell) => (
                                        <MenuItem key={headCell.id} value={headCell.id}>
                                            {headCell.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <TextField
                                variant="outlined"
                                value={searchInput || ''}
                                style={{ width: '100%', marginBottom: '30px' }}
                                onChange={handleSearchInputChange}
                                placeholder="Zoek student..."
                                label="Zoek student"
                                className='search-student-input'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon sx={{ margin: '0px', fontSize: '1rem' }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>


                        {getFilteredRows(stableSort(visibleRows, getComparator(order, orderBy)), searchInput).map((row, index) => {
                            return (
                                <Accordion key={index}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon sx={{ fontSize: '1.5rem', color: 'var(--primary)' }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        sx={{ backgroundColor: 'whitesmoke', fontWeight: '500', minHeight: '50px', borderBottom: '1px solid #e2e2e2', cursor: 'pointer' }}
                                    >
                                        <Typography sx={{ fontWeight: '500', fontSize: '0.95rem' }}>{row.name}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ textAlign: 'left' }}>
                                        <Typography>
                                            Voltooide registratie:
                                            <span style={{ color: row.completedOnboarding === 'yes' ? '#219F8E' : '', fontWeight: '500' }}> {row.completedOnboarding === 'yes' ? 'Ja' : 'Nee'}</span>
                                        </Typography>
                                        <Typography>
                                            Geziene video's: {row.videos} van de {groupVideos}

                                        </Typography>
                                        <Typography>
                                            Gemaakte quizzen: {row.quizzes} van de {groupQuizzes}
                                        </Typography>
                                        <Typography>
                                            Ingevulde vragenlijsten: {row.questionnaires} van de {groupQuestionnaires}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}
                    </CardContent>
                </Card>
            </>) : (

            // desktop table layout:
            <Box >
                <Paper
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        padding: '20px',
                        borderRadius: '20px',
                        boxShadow: 'none',
                        border: '0.5px solid #b9b9b9',
                    }}>
                    {/* <EnhancedTableToolbar numSelected={selected.length} /> */}

                    <TextField
                        variant="outlined"
                        value={searchInput || ''}
                        style={{ width: '20vw', margin: '10px' }}
                        onChange={handleSearchInputChange}
                        placeholder="Zoek student..."
                        label="Zoek student"
                        className='search-student-input'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon sx={{ marginRight: '10px', fontSize: '1.1rem' }} />
                                </InputAdornment>
                            ),
                        }}

                    />

                    <TableContainer className='student-results-table-container'

                    // sx={{ maxHeight: 340, overflowY: 'scroll', '&::-webkit-scrollbar': { width: '1em' }, '&::-webkit-scrollbar-track': 
                    // { boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)', webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)' },
                    //  '&::-webkit-scrollbar-thumb': { backgroundColor: 'var(--strong-green)', outline: '1px solid #9a9a9a', borderRadius: '20px' }
                    // }}

                    >
                        <Table
                            sx={{ width: '100%', backgroundColor: 'white' }}
                            aria-labelledby="tableTitle"
                            size={'small'}
                            className='student-results-table'
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={studentList.length}
                            />

                            <TableBody>
                                {getFilteredRows(stableSort(visibleRows, getComparator(order, orderBy)), searchInput).map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.name)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.name}
                                            selected={isItemSelected}
                                            sx={{ cursor: 'pointer' }}
                                        >

                                            <TableCell >{row.name}</TableCell>
                                            <TableCell
                                                className={`table-cell ${row.completedOnboarding === 'yes' ? 'bg-green' : 'bg-default'}`}
                                            >{row.completedOnboarding === 'yes' ? 'Ja' : 'Nee'}
                                            </TableCell>
                                            <TableCell
                                                className={`table-cell ${row.videos > 0 ? 'bg-green' : 'bg-default'}`}
                                            >
                                                {row.videos || 0}
                                            </TableCell>

                                            <TableCell
                                                className={`table-cell ${row.totalQuizzes > 0 && row.quizzes ? 'bg-green' : 'bg-default'}`}
                                            >
                                                {row.quizzes || 0}
                                            </TableCell>

                                            <TableCell
                                                className={`table-cell ${row.questionnaires > 0 ? 'bg-green' : 'bg-default'}`}
                                            >
                                                {row.questionnaires || 0}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (33) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage='Rijen per pagina'
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} van ${count !== -1 ? count : `meer dan ${to}`}`}
                        sx={{
                            margin: '10px 20px',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            '& .MuiTablePagination-actions': {
                                display: 'flex',
                                gap: '10px',

                            },


                        }}
                    />
                </Paper>
                {/* <FormControlLabel
                control={<Switch onChange={handleChangeDense} />}
                label="Dense padding"
            /> */}
            </Box>
        )
    );

}
export default StudentsTable;

