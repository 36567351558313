import React from 'react';
import {
    Box, Card, CardContent, Typography, Avatar,
} from '@mui/material';

const InfoCard = ({ icon: Icon, title, children, color }) => (
    <Card sx={{
        height: '100%',
        borderRadius: 4,
        padding: 0,
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
        },
        maxHeight: 440,
        overflowY: 'auto',
    }}>
        <CardContent sx={{ p: 2 }}>
            <Box display="flex" alignItems="center" mb={1}>
                <Avatar sx={{ bgcolor: 'transparent', color: color, width: 30, height: 30, mr: 1 }}>
                    <Icon size={18} />
                </Avatar>
                <Typography variant="subtitle1" component="div" sx={{ fontWeight: 600, color: '#555' }}>
                    {title}
                </Typography>
            </Box>
            {children}
        </CardContent>
    </Card>
);

export default InfoCard;