import React from 'react';
import Lottie from 'lottie-react';
import useInView from "../hooks/useInView";

const ScrollTriggeredLottie = ({ animationData, style }) => {
    const [animationRef, isAnimationInView] = useInView({
        threshold: 0.5,
    });

    return (
        <div className='lottie-container' ref={animationRef}>
            {isAnimationInView && (
                <Lottie
                    animationData={animationData}
                    loop={false}
                    autoplay={true}
                    style={style}
                />
            )}
        </div>
    );
};

export default ScrollTriggeredLottie;