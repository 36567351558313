import { Card, CardContent, Typography, Box, CircularProgress } from '@mui/material';

const OverallProgressCard = ({ primaryColor, accentColor, overallProgress, isMobile }) => {

    return (
        <Card sx={{
            height: '100%',
            borderRadius: 4,
            background: `linear-gradient(135deg, ${primaryColor}, ${accentColor})`,
            color: 'white',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
            '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
            }
        }}>
            <CardContent sx={{ p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Typography sx={{ color: 'white', fontWeight: 600, fontSize: isMobile ? '1.1rem' : '1.5rem' }}>
                    Uw algehele vooruitgang
                </Typography>
                <Typography gutterBottom >
                    {/* Je hebt {currentSession} sessies / {totalSessions} sessies voltooid */}
                </Typography>
                <Box
                    position="relative"
                    display="inline-flex"
                    sx={{ width: isMobile ? 100 : 150, height: isMobile ? 100 : 150, mt: '20px' }}>

                    <CircularProgress
                        variant="determinate"
                        value={overallProgress}
                        size={isMobile ? 100 : 150}
                        thickness={4}
                        sx={{
                            color: 'rgba(255,255,255,0.8)',
                            '& .MuiCircularProgress-circle': {
                                strokeLinecap: 'round',
                            },

                        }}
                    />
                    <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Typography
                            component="div"
                            sx={{ fontWeight: 700, fontSize: isMobile ? '30px' : '40px' }}>
                            {!overallProgress ? '0%' : `${overallProgress}%`}
                        </Typography>

                    </Box>
                </Box>
            </CardContent>

        </Card>
    );
}

export default OverallProgressCard;