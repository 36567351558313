import { useNavigate } from "react-router-dom"
import { useContext, useEffect, useState } from 'react';
import { Context } from "../../Context";
import { storage } from '../../firebase';
import { ref, getDownloadURL, listAll, } from "firebase/storage";
import { db } from '../../firebase';
import { collection, getDocs } from "firebase/firestore";
import Loading from "../../components/Loading";
import withAuthentication from "../../withAuthentication";
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

// UI + images
import { MenuItem, Select } from "@mui/material";
import ckBGDesktop from '../../images/ckBGDesktop.png';
import ckBGTablet from '../../images/ckBGTablet.png';
import ckBGMobile from '../../images/ckBGMobile.png';
import useScreenSize from '../../components/useScreenSize';

function Extra() {
    const { userState, loading } = useContext(Context);
    const { uid, tenantId, program, role, tenantName, group } = userState;

    const [pdfs, setPdfs] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(group ? group[0] : '');
    const [allProgramsDropdown, setAllProgramsDropdown] = useState([]);
    const [programsData, setProgramsData] = useState([]);
    const [selectedProgram, setSelectedProgram] = useState('');

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    const isCK = tenantName === "CULTUUR & KUNST" || (program && program[0] === 'MDT Cultuur & Kunst')
    const screenSize = useScreenSize(); // 'mobile', 'tablet', or 'desktop'

    const getBackgroundImage = () => {
        switch (screenSize) {
            case 'mobile':
                return ckBGMobile;
            case 'tablet':
                return ckBGTablet;
            default:
                return ckBGDesktop;
        }
    };
    const ckBackgroundStyle = {
        backgroundImage: `url(${getBackgroundImage()})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    }

    // fetch programs from firestore
    useEffect(() => {
        if (loading || !tenantId || !role || !program) return;

        const fetchAllPrograms = async () => {
            const programsRef = collection(db, 'tenants', tenantId, 'programs');
            try {
                const programSnapshot = await getDocs(programsRef);
                if (programSnapshot.empty) {
                    console.log('No matching documents.');
                    return;
                }
                const allProgramsFromDb = programSnapshot.docs.map(doc => {
                    const programData = doc.data();
                    return {
                        uid: doc.id,
                        ...programData
                    };
                });
                // all programs from firestore
                setProgramsData(allProgramsFromDb);
                // if set selected program to the first program in the array
                setSelectedProgram(allProgramsFromDb.find(programFromDb => programFromDb.name === program[0]));

            } catch (err) {
                console.error("Error fetching programs data:", err);
            }
        };
        fetchAllPrograms();

    }, [loading, tenantId]);

    // set all programs to a dropdown:
    useEffect(() => {
        if (loading || !programsData) return;
        // check if programsData is an array, if not, exit
        if (!Array.isArray(programsData)) return;
        const allProgramsDropdown = programsData.map((program, index) => {
            return (
                <MenuItem key={index} value={program.name}>
                    {program.name}
                </MenuItem>
            );
        });
        setAllProgramsDropdown(allProgramsDropdown);

    }, [loading, programsData, role]);

    // fetch pdfs from firebase storage
    useEffect(() => {
        if (loading || !uid || !selectedProgram) return;
        const fetchPdfs = async () => {
            try {
                const programName = selectedProgram.name;
                const pdfsRef = ref(storage, `${tenantId}/extra/${programName}/${selectedGroup}`);
                const pdfs = await listAll(pdfsRef)
                const pdfsArray = await Promise.all(
                    pdfs.items.map(async (itemRef) => {
                        // for each item, get the download url:
                        const url = await getDownloadURL(itemRef);
                        const pdfsObject = {
                            name: itemRef.name,
                            url: url,
                        }
                        return pdfsObject;
                    })
                )
                setPdfs(pdfsArray);
            }
            catch (err) {
                console.log(err)
            }
        }
        fetchPdfs();
    }, [loading, uid, tenantId, selectedProgram, selectedGroup])

    if (loading) return <Loading />

    return (
        <div className={`page-container ${isCK ? 'bg-overlay' : ''}`}
            style={isCK ? ckBackgroundStyle : {}}>
            <div className='page-title'>
                <h3 style={{ color: isCK ? 'white' : '' }}>Extra oefeningen</h3>
                <h5 style={{ color: isCK ? 'white' : '' }}>Open een van de PDF's om ze te bekijken, te downloaden of af te drukken</h5>
            </div>

            <div className='extra-page'>
                <div className="extra-page-header">
                    {(role === 'admin' || role === 'teacher') && programsData && programsData.length > 1 &&
                        <div>
                            <p style={{ color: isCK ? 'white' : 'var(--global-black)' }}>
                                Programma
                            </p>
                            <Select
                                id="outlined-select-currency-native"

                                value={selectedProgram?.name || ''}
                                onChange={(e) => {
                                    const selectedProgramData = programsData.find(program => program.name === e.target.value);
                                    setSelectedProgram(selectedProgramData);
                                }}
                                sx={{ minWidth: '240px', backgroundColor: isCK ? 'white' : '' }}
                            >
                                {allProgramsDropdown}
                            </Select>
                        </div>
                    }


                    <div className="homepage-group-select">
                        <p style={{ color: isCK ? 'white' : 'var(--global-black)' }}>
                            Selecteer de groep
                        </p>
                        <Select
                            value={selectedGroup || ''}
                            onChange={(e) => setSelectedGroup(e.target.value)}
                            sx={{ minWidth: '240px', backgroundColor: isCK ? 'white' : '' }}
                        >
                            {group && group.map((group, index) => (
                                <MenuItem key={index} value={group}>{group}</MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>

                {pdfs && pdfs.length > 0 ?
                    <div className="magazines-container" >
                        {pdfs && pdfs.map((pdf, index) => (
                            <div key={index} className="magazine-box" >
                                <div className=" magazine-title">
                                    <p>{pdf.name}</p>
                                </div>
                                <div className="magazine-body">
                                    <Document file={pdf.url}
                                        loading={
                                            <div style={{ marginTop: '45px' }}>
                                                <i className="fas fa-spinner fa-spin fa-xl"></i>
                                            </div>} >
                                        <Page
                                            pageNumber={1}
                                            width={250}
                                        />
                                    </Document>
                                </div>
                                <div className="magazine-box-btns">
                                    <a href={pdf.url} download={pdf.name} target="_blank" rel="noreferrer">
                                        <button>PDF Openen</button>
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                    :
                    <div className="magazines-container" >

                        <h3 style={{ color: isCK ? 'white' : 'var(--global-black)' }}>
                            Geen PDF's beschikbaar</h3>
                    </div>
                }

                {/* <div className="pagination">
                    <button onClick={() => navigate(-1)}>
                        <i className="fas fa-arrow-left"></i>
                        Terug
                    </button>

                </div> */}
            </div>
        </div>

    )
}
export default withAuthentication(Extra, false, false);