import { useState, useEffect } from 'react';

const useScreenSize = () => {
    const [screenSize, setScreenSize] = useState('desktop');

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 600) {
                setScreenSize('mobile');
            } else if (window.innerWidth < 1000) {
                setScreenSize('tablet');
            } else {
                setScreenSize('desktop');
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Call once to set initial size

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return screenSize;
};

export default useScreenSize;