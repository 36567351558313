import { useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { auth } from '../firebase';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { Context } from "../Context";
import Loading from "../components/Loading";

function Login() {
    const { setUserState, loading } = useContext(Context);

    const navigate = useNavigate();
    const location = useLocation();
    const [formData, setFormData] = useState({ email: '', password: '', });
    const [messgae, setMessage] = useState('');

    const redirectUrl = new URLSearchParams(location.search).get("redirectTo") || '/';

    const validatePassword = () => {
        let isValid = true
        const { password, email } = formData
        if (password === '' || email === '') {
            isValid = false
            setMessage('Voer een geldig e-mailadres en wachtwoord in')
        }
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        // Trim the inputs
        const trimmedEmail = email.trim();
        const trimmedPassword = password.trim();

        // Check if the email is valid
        if (!emailRegex.test(trimmedEmail)) {
            isValid = false
            setMessage('Voer een geldig e-mailadres in')
        }

        // Check if the password is valid
        if (!passwordRegex.test(trimmedPassword)) {
            isValid = false
            setMessage('Het wachtwoord moet minimaal 8 tekens bevatten, waarvan 1 letter en 1 cijfer.')
        }

        return isValid
    }

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setMessage('')
        // make sure user entered an email:
        if (!formData.email) {
            setMessage('Voer een e-mailadres in om het wachtwoord opnieuw in te stellen')
            return
        }
        try {
            const { email } = formData
            await sendPasswordResetEmail(auth, email);
            setMessage('E-mail voor wachtwoordherstel verzonden. Controleer uw e-mail en volg de instructies.')
        } catch (error) {
            console.log('Error sending password reset email: ', error.message);
            setMessage('Fout bij het verzenden van een e-mail voor het opnieuw instellen van het wachtwoord. Probeer het opnieuw.')
        }
    }

    function handleChange(e) {
        setMessage('')
        const { id, value } = e.target;
        setFormData(prevState => ({ ...prevState, [id]: value }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('')

        if (validatePassword()) {
            try {
                const { email, password } = formData
                const { user } = await signInWithEmailAndPassword(auth, email, password);

                setUserState(prevState => ({ ...prevState, uid: user.uid, }));
                navigate(redirectUrl);

            } catch (err) {
                if (err.message === 'Firebase: Error (auth/user-not-found).') {
                    setMessage('E-mail niet gevonden. Controleer uw e-mail en probeer het opnieuw')
                } else if (err.message === 'Firebase: Error (auth/wrong-password).') {
                    setMessage('Onjuist wachtwoord. Probeer het opnieuw')
                } else if (err.message === 'Firebase: Error (auth/too-many-requests).') {
                    setMessage('Te veel inlogpogingen. Wacht even en probeer het later opnieuw')
                } else if (err.message === 'Firebase: Error (auth/invalid-email).') {
                    setMessage('InvalOngeldig e-mailadres. Probeer het opnieuwid email. Please try again')
                } else if (err.message === 'Firebase: Error (auth/invalid-password).') {
                    setMessage('Ongeldig wachtwoord. Probeer het opnieuwInvalid password. Please try again')
                } else if (err.message === 'Firebase: Error (auth/user-disabled).') {
                    setMessage('Gebruiker uitgeschakeld. Neem contact op met uw beheerderUser disabled. Please contact your administrator')
                } else if (err.message === 'Firebase: Error (auth/invalid-login-credentials).') {
                    setMessage('Ongeldige inloggegevens. Probeer het opnieuwInvalid login credentials. Please try again')
                } else {
                    setMessage('Fout bij inloggen. Probeer het opnieuwError logging in. Please try again')
                }
                console.log("err.message:", err.message);
            }
        } else {
            console.log('cant submit form')
            setMessage('Wachtwoord of e-mailadres komen niet overeen. Probeer het opnieuw.Password or email do not match. Please try again.')
            setFormData({
                email: '',
                password: '',
            })
        }
    }

    if (loading) return <Loading />
    return (

        <div className="login-main-container" data-testid='login-main'>

            <div className="login-form-container">
                <div className="login-form-title">
                    <h2 >MDT PORTAAL</h2>
                    <h3 >Log in</h3>

                </div>
                <form className="login-form" onSubmit={handleSubmit}>
                    <p >Vul de inloggegevens in die je hebt ontvangen</p>
                    <input
                        type="email"
                        id="email"
                        data-testid='email'
                        placeholder="E-mail"
                        value={formData.email || ''}
                        onChange={handleChange}
                    />
                    <input
                        type="password"
                        id="password"
                        data-testid='password'
                        placeholder="Wachtwoord"
                        value={formData.password || ''}
                        onChange={handleChange}
                    />
                    <button
                        style={{ width: '100%' }}
                        type="submit"
                        data-testid='login'>Log in
                    </button>
                </form>
                {messgae && <p className="successMessage">{messgae}</p>}
                <div className="signin-help" >
                    <button
                        className="transparentBtn"
                        onClick={handleResetPassword}>
                        Wachtwoord vergeten
                    </button>
                    <a
                        href={`mailto:info@tinomdt.nl`}
                        rel="noopener noreferrer">
                        Problemen met inloggen
                    </a>
                </div>

            </div>


            <div className="pagination">
                <a
                    href="/landing"
                    rel="noopener noreferrer"
                    style={{ color: 'var(--black-logo)', textDecoration: 'underline', padding: '20px' }}
                >Back
                </a>
            </div>

        </div>
    );


}

export default Login;