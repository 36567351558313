import { useState, useEffect, useRef, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Context } from '../Context';
import { auth } from '../firebase';
import { signOut } from "firebase/auth";
import CKlogo from '../images/ckLogo.png';

// MUI 
import CustomAvatar from './CustomAvatar';
import { Divider } from "@mui/material";
import { Home } from 'lucide-react';

function Header() {
    const { userState, setUserState } = useContext(Context)
    const { isSuperAdmin, tenantName, firstName, lastName, email, program } = userState;

    const [open, setOpen] = useState(false);
    const [userName, setUserName] = useState('');

    const location = useLocation();
    let menuRef = useRef();

    const navigate = useNavigate();
    const isLoginPage = location.pathname === "/login";
    const isSignupPage = location.pathname === "/signup";
    const isLandingPage = location.pathname === "/landing";

    // check if tenant is C&K and if so, display C&K logo:
    const isCK = tenantName === "CULTUUR & KUNST" || (program && program[0] === 'MDT Cultuur & Kunst')
    const logo = isCK ? CKlogo : null;

    // prepare user name with capital letters:
    useEffect(() => {
        if (!firstName && !lastName) return;
        let name = '';
        if (firstName) name += firstName.charAt(0).toUpperCase() + firstName.slice(1);
        if (lastName) name += ' ' + lastName.charAt(0).toUpperCase() + lastName.slice(1);

        setUserName(name)
    }, [firstName, userState, lastName])


    // close menu when clicking outside of it:
    useEffect(() => {
        if (!menuRef.current) return;
        let handler = (e) => {
            if (!menuRef.current?.contains(e.target)) { setOpen(false); }
        };
        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler);
        }
    }, [open, menuRef, setOpen]);

    // sign out the user from firebase, state and redirect to login page:
    function logout() {
        signOut(auth).then(() => {
            // clear state
            setUserState({
                uid: '',
                tenantId: '',
                role: '',
                firstName: '',
                lastName: '',
                project: '',
                email: '',
                phone: '',
                address: '',
                city: '',
            });

        }).catch((error) => {
            console.log("error signing out: ", error)
        });
    }

    if (isLoginPage || isSignupPage || isLandingPage) return null;

    return (
        <header >
            <div className='nav-container'>
                <Link to="/">
                    <div className='logo-container'>
                        {logo ? <div className='logo-img'>
                            <img src={logo} alt='logo' />
                        </div> : null}
                        <div className='logo-text'>
                            <h6 >MDT Portaal</h6>
                        </div>
                    </div>
                </Link>
            </div>

            <div className='menu-container' ref={menuRef}>
                <div className='welcome-message'>
                    <p> Hoi {firstName} 👋</p>
                </div>
                <div>
                    <button
                        className='menu-trigger transparentBtn'
                        onClick={() => { setOpen(!open) }}
                        style={{ padding: '0rem', margin: '0 20px 0 25px' }}
                    >
                        <CustomAvatar letter={firstName && firstName[0]} />
                    </button>
                </div>

                <div className={`dropdown-menu ${open ? 'active' : 'inactive'}`} >
                    <ul>
                        <div onClick={() => { setOpen(!open) }} className='mobile-closing'>
                            <i style={{ fontSize: '1.8rem' }} className="fa-solid fa-xmark" ></i>
                        </div>
                        <div className='dropdownItem'>
                            <h6 >{userName}</h6>
                            <p >{email}</p>
                        </div>
                        <Divider />

                        <li className='dropdownItem'>
                            <a href={isCK ? `mailto:info@tinomdt.nl` : "mailto:info@mdtportaal.nl"}
                                className='transparentBtn'>
                                <i className="fas fa-solid fa-envelope" ></i>
                                Contact
                            </a>
                        </li>

                        <li className='dropdownItem' >
                            <button
                                className='transparentBtn'
                                onClick={() => navigate("/privacy")} >
                                <i className="fas fa-solid fa-lock" ></i>
                                Privacy Policy
                            </button>
                        </li>
                        <li className='dropdownItem' >
                            <button
                                className='transparentBtn'
                                onClick={() => navigate("/terms")} >
                                <i className="fas fa-solid fa-square-check" ></i>
                                Terms & Conditions
                            </button>
                        </li>

                        {isSuperAdmin && (
                            <li className='dropdownItem'>
                                <button
                                    className='transparentBtn'
                                    onClick={() => navigate("/superAdmin")}>
                                    <i className="fas fa-regular fa-user-shield"></i>
                                    Super Admin Panel</button>
                            </li>
                        )}
                        <Divider />
                        <li className='dropdownItem'>
                            <button
                                className='transparentBtn'
                                onClick={logout}>
                                <i className="fas fa-solid fa-arrow-right-from-bracket" ></i>
                                Uitloggen
                            </button>
                        </li>
                    </ul>
                </div>
            </div>

        </header>
    )
}
export default Header;