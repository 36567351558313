import { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom"
import { db, functions } from '../firebase';
import { collection, getDocs } from "firebase/firestore";
import withAuthentication from "../withAuthentication";
import { Context } from "../Context";
import { httpsCallable } from 'firebase/functions';

// components
import Loading from "../components/Loading";

// UI
import { TextField, Modal, Box } from "@mui/material"

// import { Tooltip } from 'react-tooltip';


function SuperAdmin() {
    const { userState, loading } = useContext(Context);
    const { isSuperAdmin } = userState;

    const [mode, setMode] = useState('create'); // Initialize with 'create' mode
    const handleClose = () => setClientModalOpen(false);

    const [newTenant, setNewTenant] = useState({
        companyName: '',
        contactPerson: '',
        companyEmail: '',
    });
    const [selectedTenant, setSelectedTenant] = useState('');
    const [allTenants, setAllTenants] = useState([]);
    const [clientModalOpen, setClientModalOpen] = useState(false);


    // ---  cloud functions --- //
    const createNewTenantInDB = httpsCallable(functions, 'createNewTenantInDB');
    const deleteTenantFromDB = httpsCallable(functions, 'deleteTenant');
    const updateTenantInDB = httpsCallable(functions, 'updateTenant');

    const openCreateClientModal = () => {
        setMode('create');
        setSelectedTenant('');
        setClientModalOpen(true);
    };

    const openUpdateClientModal = (tenant) => {
        setMode('update');
        setSelectedTenant(tenant);
        setClientModalOpen(true);
    };

    const navigate = useNavigate();
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '45vw',
        minHeight: '65vh',
        bgcolor: 'background.paper',
        border: 'none',
        boxShadow: 24,
        p: 2,
        overflow: 'scroll',
    };
    // console.log(isSuperAdmin, 'isSuperAdmin')
    // console.log(allTenants, 'allTenants')
    // console.log(selectedTenant, 'selectedTenant')

    // fetch all tenants from db
    useEffect(() => {
        if (!isSuperAdmin || loading) return;
        const fetchTenants = async () => {
            const tenants = await getDocs(collection(db, "tenants"));
            const tenantsArray = tenants.docs.map(doc => ({
                tenantId: doc.id,
                ...doc.data(),
                programCount: 0, // Placeholder count
                groupCount: 0,   // Placeholder count
                userCount: 0     // Placeholder count
            }))
            setAllTenants(tenantsArray);
            fetchCountsForTenants(tenantsArray);
        }
        const fetchCountsForTenants = async (tenants) => {
            const updatedTenants = await Promise.all(tenants.map(async (tenant) => {
                const programCount = await fetchSubcollectionCount(tenant.tenantId, 'programs');
                const groupCount = await fetchSubcollectionCount(tenant.tenantId, 'groups');
                const userCount = await fetchSubcollectionCount(tenant.tenantId, 'users');
                return { ...tenant, programCount, groupCount, userCount };
            }));
            setAllTenants(updatedTenants);
        };
        fetchTenants();
    }, [loading, isSuperAdmin])

    const convertFirestoreTimestampToDate = (timestamp) => {
        if (!timestamp || loading) return "";
        const date = new Date(timestamp.seconds * 1000); // Convert to milliseconds
        return date.toLocaleDateString("en-UK"); // Adjust the locale and format as needed
    };

    const fetchSubcollectionCount = async (tenantId, subcollectionName) => {
        const subcollectionRef = collection(db, 'tenants', tenantId, subcollectionName);
        try {
            const snapshot = await getDocs(subcollectionRef);
            return snapshot.docs.length;
        } catch (error) {
            console.log(error)
        }
    };

    const createNewClient = async (e) => {
        e.preventDefault();
        const { companyName, contactPerson, companyEmail } = e.target.elements;
        if (!companyName.value || !contactPerson.value || !companyEmail.value) return alert('Please fill in all fields');

        if (companyEmail) {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,}$/;
            if (!emailRegex.test(companyEmail.value)) return alert('Please enter a valid email address');
        }
        // confirm email as this will invite the user to the tenant
        const confirmEmail = window.confirm(`Are you sure you want to create a new client with email: ${companyEmail.value}? This will send an invite to the user to join the tenant.`);
        if (!confirmEmail) return;
        // check if email is already in use
        const emailAlreadyInUse = allTenants.some(tenant => tenant.companyEmail === companyEmail.value);
        if (emailAlreadyInUse) return alert('This email is already in use');

        // create new client
        const newClient = {
            companyName: companyName.value,
            contactPerson: contactPerson.value,
            companyEmail: companyEmail.value,
        }
        console.log(newClient, 'newClient')
        try {
            await createNewTenantInDB(newClient);
            // close modal
            handleClose();

        } catch (error) {
            alert('Something went wrong: ', error);
            console.log(error)
        }
    };

    const updateClient = async (e) => {
        e.preventDefault();
        const { companyName, contactPerson, companyEmail } = e.target.elements;
        const updatedTenant = {
            companyName: companyName.value,
            contactPerson: contactPerson.value,
            companyEmail: companyEmail.value,
            ...selectedTenant
        }
        try {
            await updateTenantInDB(updatedTenant);
            console.log('Client updated in db')
            //if succeful, refresh page
            window.location.reload();
            handleClose();
        } catch (error) {
            console.log(error)
        }
    };

    const deleteClient = async (tenant) => {
        console.log(tenant, 'tenant inside deleteClient')
        // check user is superAdmin
        if (!isSuperAdmin) return alert('You are not authorized to delete this client');
        // confirm delete
        const confirmDelete = window.confirm(`Are you sure you want to delete client: ${tenant.companyName}?`);
        if (!confirmDelete) return;
        // delete client
        try {
            await deleteTenantFromDB(tenant);
            //if succeful, refresh page
            window.location.reload();
            console.log('Client deleted from db')
        } catch (error) {
            console.log(error)
        }
    };


    if (loading) return <Loading />
    return (
        <>{userState && isSuperAdmin &&
            <div className='page-container '>

                <div className='page-title'>
                    <h3 >SuperAdmin Page</h3>
                    <h5>Create and manage MDT Portaal clients</h5>
                </div>

                <div className='superAdmin-container'>
                    <div className='superAdmin-table-buttons'>
                        <button
                            className="greyButton"
                            onClick={openCreateClientModal}>Create new client
                        </button>
                    </div>
                    <div className='superAdmin-table-container'>
                        <table style={{ textAlign: 'left' }} className='superAdmin-table'>
                            <thead>
                                <tr>
                                    {/* <th>ID</th> */}
                                    <th>Company</th>
                                    <th>Admin Name</th>
                                    <th>Admin Email</th>
                                    <th>Joined</th>
                                    <th>Programs</th>
                                    <th>Groups</th>
                                    <th>Users</th>
                                    <th>Update Client</th>
                                    <th>Delete Client</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allTenants.map(tenant =>
                                    <tr key={tenant.tenantId}>
                                        {/* <td>{tenant.tenantId}</td> */}
                                        <td>{tenant.companyName}</td>
                                        <td>{tenant.contactPerson}</td>
                                        <td>{tenant.companyEmail}</td>
                                        <td>{convertFirestoreTimestampToDate(tenant.dateAdded)}</td>
                                        <td>{tenant.programCount}</td>
                                        <td>{tenant.groupCount}</td>
                                        <td>{tenant.userCount}</td>
                                        <td>
                                            <button
                                                className="greyButton"
                                                onClick={() => openUpdateClientModal(tenant)}>Update
                                            </button>
                                        </td>
                                        <td>
                                            <button className="greyButton"
                                                onClick={() => deleteClient(tenant)}
                                            >Delete
                                            </button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* Modal for creating new client */}
                <Modal
                    open={clientModalOpen}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        {mode === 'create' && (
                            <div>
                                <h4>Add new client</h4>
                                <p>Please fill in the following fields:</p>
                            </div>
                        )}
                        {mode === 'update' && selectedTenant && (
                            <div>
                                <h4>Update info for client: "{selectedTenant.companyName}"</h4>
                                <p>Please update the following fields:</p>
                            </div>
                        )}
                        <form onSubmit={mode === 'create' ? createNewClient : updateClient}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <TextField
                                    required
                                    id="companyName"
                                    label="Company Name"
                                    defaultValue={mode === 'create' ? newTenant.companyName : selectedTenant.companyName}
                                    variant="outlined"
                                    margin="normal"
                                    onChange={(e) => {
                                        if (mode === 'create') {
                                            setNewTenant({ ...newTenant, companyName: e.target.value })
                                        } else {
                                            setSelectedTenant({ ...selectedTenant, companyName: e.target.value })
                                        }
                                    }}
                                />
                                <TextField
                                    required
                                    id="contactPerson"
                                    label="Contact Person"
                                    defaultValue={mode === 'create' ? newTenant.contactPerson : selectedTenant.contactPerson}
                                    variant="outlined"
                                    margin="normal"
                                    onChange={(e) => {
                                        if (mode === 'create') {
                                            setNewTenant({ ...newTenant, contactPerson: e.target.value })
                                        } else {
                                            setSelectedTenant({ ...selectedTenant, contactPerson: e.target.value })
                                        }
                                    }}
                                />
                                <TextField
                                    required
                                    id="companyEmail"
                                    label="Company Email"
                                    defaultValue={mode === 'create' ? newTenant.companyEmail : selectedTenant.companyEmail}
                                    variant="outlined"
                                    margin="normal"
                                    onChange={(e) => {
                                        if (mode === 'create') {
                                            setNewTenant({ ...newTenant, companyEmail: e.target.value })
                                        } else {
                                            setSelectedTenant({ ...selectedTenant, companyEmail: e.target.value })
                                        }
                                    }}
                                />
                                <button className="greyButton" type="submit">Submit</button>
                            </div>
                        </form>
                    </Box>
                </Modal>
                <div className="pagination">
                    <button className="greyButton" onClick={() => navigate(-1)}>Terug</button>
                </div>
            </div >
        }
        </>

    )
}
export default withAuthentication(SuperAdmin, false, false, true);