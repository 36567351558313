import React from 'react';
import {
    Box,
    Card,
    CardContent,
    Typography,
    List,
} from '@mui/material';
import InfoCard from './InfoCard';
import {
    Trophy
} from 'lucide-react';

const LeaderboardItem = ({ rank, name, score, activity }) => (
    <Box display="flex" alignItems="center" mb={3} sx={{ textAlign: 'left' }}>
        <Typography sx={{ width: 40 }} >
            {rank}
        </Typography>
        <Box flexGrow={1}>
            <Typography >{name}</Typography>
        </Box>
        <Box>
            <Typography
                variant="body2"
                color="text.secondary"
                sx={{ padding: 1, borderRadius: 4, backgroundColor: 'rgba(255,165,0,0.2)' }}
            >
                {score} pts
            </Typography>
        </Box>
    </Box>
);


const Leaderboard = ({ data }) => {
    // Slice the data to only take the top 10 users
    const topTen = data.slice(0, 5);

    return (

        <Card
            sx={{
                height: '100%',
                borderRadius: 4,
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
                },
            }}
        >
            {data && data.length === 0 ?

                <InfoCard icon={Trophy} title="Leiderbord" color="#FFA500" sx={{ padding: 2 }}>
                    <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', mt: 3 }}>
                        <Typography variant="body1" color="text.secondary" >
                            Nog geen studentenactiviteit
                        </Typography>
                    </Box>
                </InfoCard>

                :
                <InfoCard icon={Trophy} title="Leiderbord" color="#FFA500">
                    <CardContent sx={{ paddingTop: '0px' }}>
                        <List>
                            {topTen.map((item, index) => (
                                <LeaderboardItem
                                    key={index}
                                    rank={index + 1}
                                    name={item.displayName}  // Updated to displayName
                                    score={item.performanceScore}  // Updated to performanceScore
                                />
                            ))}
                        </List>
                    </CardContent>
                </InfoCard>
            }
        </Card>
    )
};


export default Leaderboard;