
function Spinner(props) {
    const { message } = props;
    return (
        <div className="spinner-container">
            <div className="spinner-content">
                <h3>{message ? message : 'Bezig met laden...'}</h3>
                <div style={{ marginTop: '25px' }}>
                    <i className="fas fa-spinner fa-spin fa-xl"></i>
                </div>
            </div>

        </div>
    )
}
export default Spinner;
