import { useNavigate } from "react-router-dom"
import { useContext, useEffect, useState } from 'react';
import { Context } from '../../Context';
import { db } from '../../firebase';
import { collection, getDocs, query, where } from "firebase/firestore";

import Loading from "../../components/Loading";
import StudentsTable from "../../components/StudentsTable";
import withAuthentication from "../../withAuthentication";

import { MenuItem, Select } from "@mui/material"
import ckBGDesktop from '../../images/ckBGDesktop.png';
import ckBGTablet from '../../images/ckBGTablet.png';
import ckBGMobile from '../../images/ckBGMobile.png';
import useScreenSize from '../../components/useScreenSize';

function Results() {
    const { userState, loading } = useContext(Context);
    const { group, tenantId, program, role, tenantName } = userState;

    const [students, setStudents] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState('')
    const [groupsDropdown, setGroupsDropdown] = useState([])
    const [programsData, setProgramsData] = useState([])
    const [selectedProgramData, setSelectedProgramData] = useState('')
    const [programsDropdown, setProgramsDropdown] = useState([])
    const [totalGroupVideos, setTotalGroupVideos] = useState(0)
    const [totalGroupQuizzes, setTotalGroupQuizzes] = useState(0)
    const [totalGroupQuestionaires, setTotalGroupQuestionaires] = useState(0)

    const navigate = useNavigate();
    const isCK = tenantName === "CULTUUR & KUNST" || (program && program[0] === 'MDT Cultuur & Kunst')
    const screenSize = useScreenSize(); // 'mobile', 'tablet', or 'desktop'

    console.log(group, 'group')
    const getBackgroundImage = () => {
        switch (screenSize) {
            case 'mobile':
                return ckBGMobile;
            case 'tablet':
                return ckBGTablet;
            default:
                return ckBGDesktop;
        }
    };
    const ckBackgroundStyle = {
        backgroundImage: `url(${getBackgroundImage()})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    }

    // group property from context is all groups this teacher is assigned to. it might be just 1 or many in an array. 
    // if there is only 1 group, set it as the selected group. if there are many groups, set the first group as the selected group.

    useEffect(() => {
        if (loading || !group || group.length === 0) return
        // check if group is an array or a string:
        const groupIsArray = Array.isArray(group)
        if (!groupIsArray) {
            setSelectedGroup(group)
        } else {
            const singleGroup = group[0]
            setSelectedGroup(singleGroup)
            const groupsDropdown = group.map((group, index) => {
                return <MenuItem key={index} value={group}>
                    {group}
                </MenuItem>
            })
            setGroupsDropdown(groupsDropdown)
        }
    }, [group, loading]);

    // based on the program name/s in the userstate, search the programs collection and for each match return the program data:
    useEffect(() => {
        if (loading || !program || !tenantId) return
        const fetchPrograms = async () => {
            try {
                const programsRef = collection(db, 'tenants', tenantId, 'programs');
                // return all programs
                const programSnapshot = await getDocs(programsRef);
                if (programSnapshot.empty) {
                    console.log('No matching documents.');
                    return;
                }
                // map through the programs and return the data for the programs in the program array in the userState:
                const programsData = programSnapshot.docs.map(doc => {
                    const data = {
                        id: doc.id,
                        ...doc.data()
                    }
                    return data
                });
                // set to state all programs:
                setProgramsData(programsData)
                // filter the programs based on the program name in the userState:

                const userProgram = program[0]
                const userProgramData = programsData.find(program => program.name === userProgram || program.id === userProgram)
                setSelectedProgramData(userProgramData)

            } catch (error) {
                console.log("Error getting documents: ", error);
            }
        }
        fetchPrograms();
    }, [loading, program, tenantId, role, selectedGroup]);

    // if there are more than 1 program, prepare dropwdown for the user to select the program:
    useEffect(() => {
        if (loading || !program || program.length === 0) return
        if (program.length > 1) {
            const programsDropdown = program.map((program, index) => {
                return <MenuItem key={index} value={program}>
                    {program}
                </MenuItem>
            })
            setProgramsDropdown(programsDropdown)
        }
    }, [loading, program]);


    // fetch students in the group:
    useEffect(() => {
        if (loading || !group || group.length === 0 || !selectedGroup) return
        const fetchStudents = async () => {
            try {
                const usersRef = collection(db, 'tenants', tenantId, 'users');
                const groupQuery = query(usersRef, where("group", "array-contains", selectedGroup));
                const querySnapshot = await getDocs(groupQuery);

                // only return students in the same group:
                const students = querySnapshot.docs.map((doc) => doc.data());

                // filter users who are in the teacher's group:
                const studentsInGroup = students.filter(student => student.group.includes(selectedGroup))

                // filter out users who are teachers or admins:
                const studentsInGroupFiltered = studentsInGroup.filter(student => student.role === 'user')
                setStudents(studentsInGroupFiltered)

            } catch (error) {
                console.log('error', error)
            }
        }
        fetchStudents()
    }, [group, loading, tenantId, selectedGroup]);

    const handleGroupSelection = (e) => {
        setSelectedGroup(e.target.value)
    }

    // fetch group info and set the total number of videos and quizzes in the group:
    useEffect(() => {
        if (loading || !selectedGroup || !tenantId) return
        const fetchGroupInfo = async () => {
            try {
                const groupsRef = collection(db, 'tenants', tenantId, 'groups');
                const groupsSnapshot = await getDocs(groupsRef);
                const groupsData = groupsSnapshot.docs.map(doc => {
                    const data = {
                        id: doc.id,
                        ...doc.data()
                    }
                    return data
                }
                );
                const groupData = groupsData.find(group => group.name === selectedGroup)

                if (groupData) {
                    // map over the group schedule, and find the total number of videos and quizzes:
                    // in each session, check if there's a questionnaire string, if there is count it as a questionnaire. questionnaire is not an array, it's a string.
                    const totalGroupQuestionnaires = groupData.schedule.reduce((acc, session) => {
                        if (session.questionnaire) {
                            return acc + 1
                        }
                        return acc
                    }, 0)

                    const totalGroupVideos = groupData.schedule.reduce((acc, session) => {
                        if (session.videos) {
                            return acc + session.videos.length
                        }
                        return acc
                    }
                        , 0)

                    setTotalGroupQuestionaires(totalGroupQuestionnaires)
                    setTotalGroupVideos(totalGroupVideos)
                    setTotalGroupQuizzes(totalGroupVideos) // for now, quizzes are counted as videos.
                }
            } catch (error) {
                console.log('error fetching group info', error)
            }
        }
        fetchGroupInfo()
    }, [selectedGroup, loading, tenantId]);

    const handleProgramSelection = (e) => {
        const selectedProgram = e.target.value
        // find the program data based on the selected program:
        const programData = programsData.find(program => program.name === selectedProgram)
        setSelectedProgramData(programData)
    }

    if (loading) return <Loading />

    return (
        <div className={`page-container ${isCK ? 'bg-overlay' : ''}`}
            style={isCK ? ckBackgroundStyle : {}}>
            <div className='page-title'>
                <h3 style={{ color: isCK ? 'white' : 'var(--global-black)' }} >Resultaten van studenten</h3>
                <h5 style={{ color: isCK ? 'white' : '' }}>Analyseer de prestatie van uw studenten</h5>
            </div>
            <div className="results-top-buttons">
                {Array.isArray(program) && program.length > 1 &&
                    <div style={{ marginTop: '15px' }} >
                        <h6 style={{ color: isCK ? 'white' : 'var(--global-black)' }}>Programma</h6>
                        <Select
                            disabled={!Array.isArray(program)}
                            value={selectedProgramData ? selectedProgramData.name : ''}
                            label="Selected Program"
                            style={{ width: '200px', marginTop: '5px', backgroundColor: isCK ? 'white' : '' }}
                            displayEmpty
                            onChange={(e) => handleProgramSelection(e)}
                            renderValue={(value) => {
                                if (value === '') {
                                    return <p>Selecteer programma</p>;
                                }
                                return value;
                            }}
                        >
                            {programsDropdown !== '' && programsDropdown}
                        </Select>

                    </div>
                }
                {Array.isArray(group) && (role === 'teacher' || role === 'admin') &&
                    <div style={{ marginTop: '15px' }} >
                        <h6 style={{ color: isCK ? 'white' : 'var(--global-black)' }}>Group</h6>
                        <Select
                            disabled={group.length === 1}
                            value={selectedGroup ? selectedGroup : ''}
                            label="Selected Group"
                            style={{ width: '250px', marginTop: '5px', backgroundColor: isCK ? 'white' : '' }}
                            displayEmpty
                            renderValue={(value) => {
                                if (value === '') {
                                    return <p>Selecteer group</p>;
                                }
                                return value;
                            }}
                            onChange={(e) => handleGroupSelection(e)}
                        >
                            {groupsDropdown !== '' && groupsDropdown}
                        </Select>

                    </div>
                }
            </div>
            <div className='page-content' >

                <div >
                    {!students || students.length === 0 ?
                        loading ? <Loading /> :
                            <h5 style={{ color: isCK ? 'white' : 'var(--global-black)' }}>No students in this group</h5>
                        : <StudentsTable
                            style={{ width: '100%' }}
                            students={students}
                            programData={selectedProgramData}
                            totalVideos={totalGroupVideos}
                            totalQuizzes={totalGroupQuizzes}
                            totalQuestionaires={totalGroupQuestionaires} />}
                </div>
            </div>

            {/* <div className="pagination">
                <button onClick={() => navigate(-1)}>
                    <i className="fas fa-arrow-left"></i>
                    Terug
                </button>
            </div> */}
        </div>

    );
}
export default withAuthentication(Results, false, true);