import { useNavigate } from "react-router-dom"
import { useContext, useEffect, useState } from 'react';
import { Context } from '../../Context';
import Loading from "../../components/Loading";
import Spinner from "../../components/Spinner";
import withAuthentication from "../../withAuthentication";
import { db } from '../../firebase';
import { doc, onSnapshot } from "firebase/firestore";
import { Box, Stepper, Step, StepLabel } from '@mui/material';
// import Checkbox from '@mui/material/Checkbox';

function Step1() {
    // db data via context:
    const { userState, loading, updateData } = useContext(Context);
    const { email, onboardingProcess, onboardingData, role, firstName, lastName, uid, tenantId, program } = userState;

    const navigate = useNavigate();

    const [stepsTitles, setStepsTitles] = useState([]);
    // const studentsStepsTitles = ['Teken toestemmingsverklaring', 'Onderteken overheidsdocumenten', 'Persoonlijk doel']
    const studentsStepsTitles = ['Teken toestemmingsverklaring', 'Onderteken overheidsdocumenten']
    const teachersStepsTitles = ['Teken schoolovereenkomst', 'Upload studentenlijst', 'Voeg notities toe']


    // const [checked, setChecked] = useState(false)
    const [selectedEmail, setSelectedEmail] = useState(email)
    const [showSpinner, setShowSpinner] = useState(true);

    const pdfFileUrl = 'https://www.mdtportaal.nl/files/algemene-voorwaarden-TINO-MDT-scholen.pdf' // pdf file of the teachers agreement for download (without signature)
    const studentFormUrl = `https://zfrmz.eu/t8TdIZBhbYreG581OYOl?eml=${email}&fn=${firstName}&lsnm=${lastName}`
    const teacherFormUrl = `https://zfrmz.eu/jV5iIYaUmhNxKesHmvc9?eml=${email}&fn=${firstName}&lsnm=${lastName}`
    const studentFromUrlCK = `https://zfrmz.eu/ieN19lgVzjPbpvgJLvIV?eml=${email}&fn=${firstName}&lsnm=${lastName}`


    const formUrl = role !== 'teacher' ? (program && program[0] !== 'MDT Cultuur & Kunst' ? studentFormUrl : studentFromUrlCK) : teacherFormUrl

    // check role, change steps titles accordingly:
    useEffect(() => {
        if (loading || !userState || !role) return
        role === 'teacher' ? setStepsTitles(teachersStepsTitles) : setStepsTitles(studentsStepsTitles)
    }, [loading, userState, role]);

    // if user already signed, check what page is next:
    useEffect(() => {
        if (loading || !userState) return
        if (onboardingProcess.step1 === true && onboardingData.signed === true) {
            if (onboardingProcess.step2 === false) {
                navigate('/onboarding/step2')
            } else if (role && role === 'teacher' && onboardingProcess.step3 === false) {
                navigate('/onboarding/step3')
            } else {
                navigate('/')
            }
        }
    }, [loading, onboardingProcess, navigate, onboardingData, userState]);

    // check if teacher marked check mark, update onboarding process and navigate to next step:
    const handleNextStep = async () => {
        // confirm if teacher checked the checkbox:
        const confirmCheck = window.confirm(
            (role === 'teacher') ?
                'Heeft u het vakje aan het einde van het formulier aangevinkt, waarmee u bevestigt dat u het heeft gelezen?' :
                'Heeft u het formulier ingevuld en op de knop "verzenden" geklikt?'

        )

        if (!confirmCheck) return

        const updatedOnboardingProcess = {
            ...onboardingProcess,
            step1: true
        }

        try {
            await updateData('onboardingProcess', updatedOnboardingProcess)
            if (role === 'user') {
                await updateData('onboardingData', { signed: true })
            }
            navigate('/onboarding/step2')
        } catch {
            alert('Er is iets misgegaan. Probeer het later opnieuw')
        }

    }

    function handleEmailSend() {
        window.location.href =
            `mailto:${selectedEmail}?subject=MDT Algemene&body=Algemene voorwaarden deelname scholen MDT. Hier is de link naar de overeenkomst: ${pdfFileUrl}`
    }

    // add a listener to check if form is submitted and updated in db:
    useEffect(() => {
        if (loading || !userState || !uid || !tenantId) return

        const checkFormSubmissionStatus = async () => {
            try {
                const userRef = doc(db, 'tenants', tenantId, 'users', uid);
                // listen for real-time updated using onSnapshot():
                const unsubscribe = onSnapshot(userRef, (userSnap) => {
                    if (!userSnap.exists()) {
                        return;
                    }
                    const userData = userSnap.data();
                    if (!userData) {
                        return;
                    }
                    // look for onboardingData and onboardingProcess in userData:
                    const { onboardingData, onboardingProcess } = userData;
                    if (!onboardingData || !onboardingProcess) {
                        return;
                    }
                    // check if form is submitted:
                    if (onboardingData.signed === true && onboardingProcess.step1 === true) {
                        navigate('/onboarding/step2')
                    }
                })
                // Clean up the listener on component unmount
                return () => {
                    unsubscribe();
                }
            } catch (error) {
                console.log(error)
            }
        }
        checkFormSubmissionStatus();

    }, [loading, userState, uid, tenantId]);


    if (loading) return <Loading />

    return (
        <div className='page-container'>

            <div className='onboarding-content-container'>
                <div style={{ textAlign: 'left' }}>
                    <button onClick={() => navigate('/')}
                        style={{ padding: '8px' }}
                        className="yellow-btn">X</button>
                </div>

                <div className='onboarding-title step1'>
                    <h5 >Stap 1: {role === 'teacher' ? 'Teken schoolformulier' : 'onderteken de programmadocumenten'}</h5>
                </div>
                <div data-testid='step1-main' className='onboarding-body'>
                    <div className="students-agreement-container">
                        <div className="iframe-wrapper">
                            {showSpinner && (
                                <div className="spinner-content-iframe">
                                    <h3>De overeenkomst laden...</h3>
                                    <div style={{ marginTop: '25px' }}>
                                        <i className="fas fa-spinner fa-spin fa-xl"></i>
                                    </div>
                                </div>

                            )}
                            <iframe
                                data-testid='step1-iframe'
                                title='formFrame'
                                src={formUrl}
                                onLoad={() => setShowSpinner(false)} // hide spinner when form is loaded
                            >
                            </iframe>
                        </div>

                    </div>

                    {role === 'teacher' && (
                        <div className='step1-teacher-buttons' data-testid='step1-teacher-buttons'>
                            <div className="teacher-agreement-buttons">
                                <a
                                    data-testid="step1-download-link"
                                    className="yellow-btn"
                                    style={{ padding: '10px', borderRadius: '5px', textDecoration: 'none', width: '230px' }}
                                    href={pdfFileUrl}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    Overeenkomst downloaden <i style={{ marginLeft: '5px' }} className="fas fa-download"></i>
                                </a>
                                <button
                                    data-testid="step1-email-button"
                                    className="yellow-btn"
                                    onClick={handleEmailSend}>
                                    E-mailovereenkomst <i style={{ marginLeft: '5px' }} className="fas fa-paper-plane"> </i>
                                </button>


                                {/* <input
                                    type="text"
                                    placeholder="Voer uw e-mailadres in"
                                    style={{ width: '230px', margin: '0px', padding: '8px', borderRadius: '5px', outline: 'none', textAlign: 'center' }}
                                    onChange={(e) => setSelectedEmail(e.target.value)}
                                    value={selectedEmail || ''}
                                >
                                </input> */}

                            </div>

                        </div>
                    )}
                </div>
                <div>
                    <button
                        data-testid='step1-next-button'
                        onClick={handleNextStep} >
                        Volgende
                    </button>
                </div>
                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={0} alternativeLabel>
                        {stepsTitles.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
            </div>
        </div>

    )
}
export default withAuthentication(Step1);