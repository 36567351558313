import { useNavigate } from "react-router-dom"
import { useContext, useEffect, useState } from 'react';
import { Context } from '../../Context';
import Loading from "../../components/Loading";
import { db } from '../../firebase';
import { setDoc, doc, getDoc, onSnapshot } from "firebase/firestore";
import Spinner from "../../components/Spinner";
import withAuthentication from "../../withAuthentication";
import { TextField, Box, Stepper, Step, StepLabel } from '@mui/material';


function Step3() {
    const { userState, loading, updateData } = useContext(Context);
    const { onboardingProcess, onboardingData, role, group, tenantId, email, firstName, lastName, uid, program } = userState;

    // const studentsStepsTitles = ['Teken toestemmingsverklaring', 'Onderteken overheidsdocumenten', 'Persoonlijk doel']
    const studentsStepsTitles = ['Teken toestemmingsverklaring', 'Onderteken overheidsdocumenten']
    const teachersStepsTitles = ['Teken schoolovereenkomst', 'Upload studentenlijst', 'Voeg notities toe']
    const [stepsTitles, setStepsTitles] = useState(studentsStepsTitles);
    const [message, setMessage] = useState('')
    // const [goal, setGoal] = useState({
    //     option1: {
    //         answer1: '',
    //         answer2: '',
    //         answer3: '',
    //     },
    //     option2: {
    //         answer1: '',
    //         answer2: '',
    //         answer3: '',
    //     }
    // })

    const [teacherGroupData, setTeacherGroupData] = useState([])
    const [selectedGroup, setSelectedGroup] = useState('');
    const [teacherNotes, setTeacherNotes] = useState('')
    const [showSpinner, setShowSpinner] = useState(false);
    const formURL = `https://zfrmz.eu/YpqVP0ZqIwfng218OX5u?eml=${email}&fn=${firstName}&lsnm=${lastName}`
    const navigate = useNavigate();

    // styling for MUI TextField:
    const inputStyle = {
        inputLabel: {
            color: '#2f3540',
            fontSize: '1.2rem',
            fontWeight: 'bold',
        }
    }
    // check if user is teacher, and change steps titles accordingly:
    useEffect(() => {
        if (loading || !role) return
        role === 'teacher' ? setStepsTitles(teachersStepsTitles) : setStepsTitles(studentsStepsTitles)
    }, [loading, role])

    // check if user has already completed this step, and redirect accordingly:
    useEffect(() => {
        if (loading || !onboardingProcess || !onboardingData) return

        if (role && role === 'user') {
            navigate('/')
            return
        }
        // they can't get to step 3 if they haven't completed step 2:
        if (!onboardingProcess.step2) {
            navigate('/onboarding/step2')
            return
        }
        if (onboardingProcess.step3) {
            navigate('/')
        }
    }, [loading, onboardingProcess, onboardingData])

    // grab the first group in group array and set it in state:
    useEffect(() => {
        if (loading || !group || group.length === 0) return

        // TODO: add support for multiple groups:
        setSelectedGroup(group[0])
    }, [loading, group])

    // fetch teacher's group data:
    useEffect(() => {
        if (loading || !group || group.length === 0 || !selectedGroup) return
        // fetch all groupData + sessions for the group:
        const fetchGroup = async () => {
            try {
                const groupRef = doc(db, "tenants", tenantId, "groups", selectedGroup);
                const groupSnap = await getDoc(groupRef);
                const groupData = groupSnap.data();
                if (groupSnap.exists()) {
                    setTeacherGroupData(groupData)
                }

            } catch (error) {
                console.log('error', error)
            }
        }
        fetchGroup()
    }, [group, loading, tenantId, selectedGroup]);

    // set notes/goal in state:
    const handleChange = (e) => {
        const { name, value } = e.target;
        // if user is teacher, set notes:
        if (name === 'notes') {
            setTeacherNotes(value)
            return
        }
    }

    const saveForm = async () => {
        setMessage('Top, je bent klaar! We zien je bij de volgende sessie! Je wordt nu doorgestuurd naar de homepage.')
        let updatedOnboardingData = {}
        let updatedGroupData = {}

        updatedOnboardingData = {
            ...onboardingData,
            notes: teacherNotes,
        }
        updatedGroupData = {
            ...teacherGroupData,
            notes: teacherNotes,
        }
        const updateOnboardingProcess = {
            ...onboardingProcess,
            step3: true,
        }

        // removed because students don't have step 3

        // else {
        //     updatedOnboardingData = {
        //         ...onboardingData,
        //         personalGoal: goal,
        //     }
        // }
        // const updateOnboardingProcess = {
        //     ...onboardingProcess,
        //     step3: true,
        // }

        setShowSpinner(true)
        try {
            updateData('onboardingData', updatedOnboardingData)
            updateData('onboardingProcess', updateOnboardingProcess)

            // if user is teacher, update group data:
            if (role === 'teacher') {
                const groupRef = doc(db, "tenants", tenantId, "groups", selectedGroup);
                await setDoc(groupRef, updatedGroupData);
                console.log('updated group data in db with teachers notes')
            }
        } catch (error) {
            console.log('error', error)
            alert('Something went wrong, please try again later')
        } finally {
            setTimeout(() => {
                setShowSpinner(false)
                navigate('/')
            }, 3500)
        }
    }

    // add a listener to check if form is submitted and updated in db:
    useEffect(() => {
        if (loading || !userState || !uid || !tenantId) return
        const checkFormSubmissionStatus = async () => {
            try {
                const userRef = doc(db, 'tenants', tenantId, 'users', uid);
                // listen for real-time updated using onSnapshot():
                const unsubscribe = onSnapshot(userRef, (userSnap) => {
                    if (!userSnap.exists()) {
                        return;
                    }
                    const userData = userSnap.data();
                    if (!userData) {
                        return;
                    }
                    // look for onboardingData and onboardingProcess in userData:
                    const { onboardingProcess } = userData;
                    if (!onboardingProcess) {
                        return;
                    }
                    // check if form is submitted:
                    if (onboardingProcess && onboardingProcess.step3 === true) {
                        navigate('/')
                    }
                })
                // Clean up the listener on component unmount
                return () => {
                    unsubscribe();
                }
            } catch (error) {
                console.log(error)
            }
        }
        checkFormSubmissionStatus();

    }, [loading, userState, uid, tenantId]);

    if (loading) return <Loading />
    return (
        <div className='page-container'>
            <div className='onboarding-content-container'>
                {showSpinner && <Spinner message={message} />}
                <div className="onboarding-closing-icon-container">
                    <button
                        onClick={() => navigate('/')}
                        className="yellow-btn">X
                    </button>
                </div>
                <div className='onboarding-title'>
                    <h5>{role !== 'teacher' ? 'Stap 3: Waar wil jij in groeien' : 'Stap 3: Voeg opmerkingen over studenten toe'}</h5>
                    <p > {role !== 'teacher' ?
                        '' :
                        "Heeft u leerlingen met specifieke behoeften of aandoeningen, zoals allergieën, handicaps of zorgvragen? Wij vinden het belangrijk om een veilige en inclusieve omgeving te garanderen voor elk kind. Als u nog iets onder onze aandacht wilt brengen, of aanvullende informatie wilt geven waarvan u denkt dat die ons zou helpen ons beter voor te bereiden op de workshop, deel dit dan alstublieft met ons in de daarvoor bestemde ruimte hieronder."}
                    </p>
                </div>
                {role && role !== 'teacher' &&
                    <div className="students-agreement-container">
                        <iframe
                            data-testid='step3-iframe'
                            title='formFrame'
                            src={formURL}
                            onLoad={() => setShowSpinner(false)} // hide spinner when form is loaded
                        >
                        </iframe>
                    </div>

                }
                {role && role === 'teacher' &&
                    <div className='onboarding-body teacher'>
                        <div className='onboarding-input-container teacher'>
                            <TextField
                                name="notes"
                                value={teacherNotes || ''}
                                multiline
                                fullWidth
                                rows={5}
                                onChange={handleChange}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                    style: inputStyle.inputLabel
                                }}
                                placeholder="Bijvoorbeeld: ik denk dat deze student erg gemotiveerd is en veel potentieel heeft. Ik denk dat hij/zij baat zou hebben bij een mentor die erg gestructureerd is en hem/haar kan helpen met plannen en het stellen van doelen."
                            />
                        </div>
                    </div>
                }
                <p className="successMessage">{message}</p>

                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={2} alternativeLabel>
                        {stepsTitles && stepsTitles.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>

            </div>
            <div className="bottomNavigation" style={{ justifyContent: 'space-between' }}>
                <button onClick={() => navigate('/onboarding/step2')}>Terug naar stap 2</button>
                <button onClick={saveForm} >Sla op en voltooi registratie</button>

            </div>
        </div>

    )
}
export default withAuthentication(Step3);




// <div style={{ flexDirection: 'row', marginTop: '20px' }} className='onboarding-body'>
//     <div className='onboarding-input-container'>
//         <h5 >Optie 1: jouw doel</h5>
//         <Accordion >
//             <AccordionSummary
//                 expandIcon={<ExpandMoreIcon />}
//                 aria-controls="panel1a-content"
//                 id="panel1a-header"
//             >
//                 <Typography style={{ fontWeight: '500' }}>Vraag 1</Typography>
//             </AccordionSummary>
//             <AccordionDetails >
//                 <Typography >
//                     Beschrijf hier een doel dat je hebt in je eigen leven en binnen 1 jaar wilt bereiken. Dit mag gaan over je opleiding, een hobby of andere droom die je hebt.
//                 </Typography>
//                 <TextField
//                     name="option1.answer1"
//                     sx={{ mt: 1 }}
//                     value={goal.option1.answer1 || ''}
//                     multiline
//                     fullWidth
//                     minRows={3}
//                     onChange={handleChange}
//                     variant="outlined"
//                     InputLabelProps={{
//                         shrink: true,
//                         style: inputStyle.inputLabel
//                     }}
//                 />
//             </AccordionDetails>
//         </Accordion>
//         <Accordion>
//             <AccordionSummary
//                 expandIcon={<ExpandMoreIcon />}
//                 aria-controls="panel2a-content"
//                 id="panel2a-header"
//             >
//                 <Typography style={{ fontWeight: '500' }}>Vraag 2</Typography>
//             </AccordionSummary>
//             <AccordionDetails>
//                 <Typography>Waarom wil je dit doel bereiken?</Typography>
//                 <TextField
//                     name="option1.answer2"
//                     sx={{ mt: 1 }}
//                     value={goal.option1.answer2 || ''}
//                     multiline
//                     fullWidth
//                     rows={4}
//                     onChange={handleChange}
//                     variant="outlined"
//                     InputLabelProps={{
//                         shrink: true,
//                         style: inputStyle.inputLabel
//                     }}
//                 />
//             </AccordionDetails>
//         </Accordion>
//         <Accordion >
//             <AccordionSummary
//                 expandIcon={<ExpandMoreIcon />}
//                 aria-controls="panel3a-content"
//                 id="panel3a-header"
//             >
//                 <Typography style={{ fontWeight: '500' }}>Vraag 3</Typography>
//             </AccordionSummary>
//             <AccordionDetails>
//                 <Typography>Wat doe je al om je doel te bereiken en welke uitdagingen zie je in het bereiken van dit doel?</Typography>
//                 <TextField
//                     name="option1.answer3"
//                     sx={{ mt: 1 }}
//                     value={goal.option1.answer3 || ''}
//                     multiline
//                     fullWidth
//                     rows={4}
//                     onChange={handleChange}
//                     variant="outlined"
//                     InputLabelProps={{
//                         shrink: true,
//                         style: inputStyle.inputLabel
//                     }}
//                 />
//             </AccordionDetails>
//         </Accordion>
//     </div>
//     <div className='onboarding-input-container'>
//         <h5 >Optie 2: jouw keuze</h5>
//         <Accordion>
//             <AccordionSummary
//                 expandIcon={<ExpandMoreIcon />}
//                 aria-controls="panel1a-content"
//                 id="panel1a-header"
//             >
//                 <Typography style={{ fontWeight: '500' }}>Vraag 1</Typography>
//             </AccordionSummary>
//             <AccordionDetails >
//                 <Typography >Beschrijf hier een belangrijke keuze waar je mee bezig bent. Bijv. of je wel/niet gaat stoppen met je hobby, of je wel/niet naar een andere school wilt of wat je na je opleiding zou willen gaan doen.</Typography>
//                 <TextField
//                     name="option2.answer1"
//                     sx={{ mt: 1 }}
//                     value={goal.option2.answer1 || ''}
//                     multiline
//                     fullWidth
//                     rows={4}
//                     onChange={handleChange}
//                     variant="outlined"
//                     InputLabelProps={{
//                         shrink: true,
//                         style: inputStyle.inputLabel
//                     }}
//                 />
//             </AccordionDetails>
//         </Accordion>
//         <Accordion>
//             <AccordionSummary
//                 expandIcon={<ExpandMoreIcon />}
//                 aria-controls="panel2a-content"
//                 id="panel2a-header"
//             >
//                 <Typography style={{ fontWeight: '500' }}>Vraag 2</Typography>
//             </AccordionSummary>
//             <AccordionDetails>
//                 <Typography >Wat doe je al om aan deze keuze te werken en is er iets wat de keuze lastig maakt? Zo ja, wat?</Typography>
//                 <TextField
//                     name="option2.answer3"
//                     sx={{ mt: 1 }}
//                     value={goal.option2.answer3 || ''}
//                     multiline
//                     fullWidth
//                     rows={4}
//                     onChange={handleChange}
//                     variant="outlined"
//                     InputLabelProps={{
//                         shrink: true,
//                         style: inputStyle.inputLabel
//                     }}
//                 />
//             </AccordionDetails>
//         </Accordion>
//         <Accordion >
//             <AccordionSummary
//                 expandIcon={<ExpandMoreIcon />}
//                 aria-controls="panel3a-content"
//                 id="panel3a-header"
//             >
//                 <Typography style={{ fontWeight: '500' }}>Vraag 3</Typography>
//             </AccordionSummary>
//             <AccordionDetails>
//                 <Typography >Waarom is deze keuze belangrijk voor je?</Typography>
//                 <TextField
//                     name="option2.answer2"
//                     sx={{ mt: 1 }}
//                     value={goal.option2.answer2 || ''}
//                     multiline
//                     fullWidth
//                     rows={4}
//                     onChange={handleChange}
//                     variant="outlined"
//                     InputLabelProps={{
//                         shrink: true,
//                         style: inputStyle.inputLabel
//                     }}
//                 />
//             </AccordionDetails>
//         </Accordion>
//     </div>
// </div>